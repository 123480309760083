import { createContext, useState, useContext, useEffect } from 'react';

const ThemeContext = createContext();

// Thème par défaut violet avec un gradient plus harmonieux
export const DEFAULT_THEME = {
  primary: '#6B21A8',
  secondary: '#1F1544',
  background: '#130C2E',
  gradient: `linear-gradient(125deg, 
    #8021C8 -10%,
    #6B21A8 15%,
    #581C87 30%,
    #3B1667 50%,
    #1F1544 75%
  )`,
};
 // Autre theme violet stylé ==> Avis Pedro ? 
/**
export const DEFAULT_THEME = {
  primary: '#6B21A8',
  secondary: '#1F1544',
  background: '#130C2E',
  gradient: `linear-gradient(125deg, 
    #c77dff -10%,
    #9d4edd 15%,
    #7b2cbf 30%,
    #3B1667 50%,
    #1F1544 75%
  )`,
};
**/

/**
export const DEFAULT_THEME = {
  primary: '#6B21A8',
  secondary: '#1F1544',
  background: '#130C2E',
  gradient: `linear-gradient(125deg, 
    #c77dff -10%,
    #9d4edd 15%,
    #7b2cbf 30%,
    #8021C8 -10%,
    #6B21A8 15%,
    #581C87 30%,
    #3B1667 50%,
    #1F1544 75%
  )`,
};
**/

// Mapping des couleurs d'icônes vers des palettes plus douces
const colorPalettes = {
  // FPS - Rouge atténué vers bordeaux
  '#ff4757': {
    gradient: 'linear-gradient(165deg, #e8616f99, #802436)',
    primary: '#e8616f',
    secondary: '#802436'
  },
  // MOBA - Vert sage vers émeraude
  '#2ed573': {
    gradient: 'linear-gradient(to right, #7fb8a488, #2c6e4a)',
    primary: '#7fb8a4',
    secondary: '#2c6e4a'
  },
  // Battle Royale - Orange doux vers terracotta
  '#ffa502': {
    gradient: 'linear-gradient(135deg, #e9967a88, #8b4513)',
    primary: '#e9967a',
    secondary: '#8b4513'
  },
  // Course - Bleu ciel vers indigo
  '#1e90ff': {
    gradient: 'linear-gradient(to right, #87ceeb99, #1a365d)',
    primary: '#87ceeb',
    secondary: '#1a365d'
  },
  // Combat - Rose poudré vers prune
  '#ff6b81': {
    gradient: 'linear-gradient(165deg, #dda0dd88, #4a1f4a)',
    primary: '#dda0dd',
    secondary: '#4a1f4a'
  },
  // Casino - Gris perle vers anthracite
  '#a4b0be': {
    gradient: 'linear-gradient(135deg, #b8c6db88, #36454f)',
    primary: '#b8c6db',
    secondary: '#36454f'
  },
  // RPG - Violet lavande vers aubergine
  '#8e44ad': {
    gradient: 'linear-gradient(to bottom right, #9f8fdb88, #432c51)',
    primary: '#9f8fdb',
    secondary: '#432c51'
  },
  // Par défaut - Gradient doux neutre
  default: {
    gradient: 'linear-gradient(135deg, #a8b5cc88, #45526e)',
    primary: '#a8b5cc',
    secondary: '#45526e'
  },
  // MMO - Bleu glacier vers bleu nuit
  '#2ecc71': {
    gradient: 'linear-gradient(135deg, #a8d8e888, #2c5784)',
    primary: '#a8d8e8',
    secondary: '#2c5784'
  },
  // Stratégie - Ocre doux vers brun
  '#e67e22': {
    gradient: 'linear-gradient(165deg, #deb88788, #5c4033)',
    primary: '#deb887',
    secondary: '#5c4033'
  },
  // Sports - Bleu foncé avec plus de luminosité
  '#5B8A72': {
    gradient: 'linear-gradient(135deg, #2B4C6F 0%, #1B2F45 50%, #0A192F 100%)',
    primary: '#2B4C6F',
    secondary: '#0A192F',
    background: '#060D1B'
  },
  // Retro - Rose vintage vers bordeaux
  '#e84393': {
    gradient: 'linear-gradient(135deg, #fadadd88, #6b2b42)',
    primary: '#fadadd',
    secondary: '#6b2b42'
  },
  // Arcade - Version moins flashy, plus rétro-futuriste
  '#00b894': {
    gradient: 'linear-gradient(135deg, #4A5D7E 0%, #2C3E50 50%, #1A2634 100%)',
    primary: '#4A5D7E',    // Bleu-gris métallique
    secondary: '#1A2634',  // Version plus sombre
    background: '#131C26'  // Fond très sombre
  },
  // Mobile - Bleu électrique doux vers marine
  '#6c5ce7': {
    gradient: 'linear-gradient(165deg, #a7c7e788, #1e3d59)',
    primary: '#a7c7e7',
    secondary: '#1e3d59'
  },
  // Aventure - Turquoise vers pétrole
  '#00cec9': {
    gradient: 'linear-gradient(to right, #88d8c088, #05464e)',
    primary: '#88d8c0',
    secondary: '#05464e'
  },
  // Simulation - Vert d'eau vers émeraude foncé
  '#55efc4': {
    gradient: 'linear-gradient(135deg, #96ded188, #2d5a4c)',
    primary: '#96ded1',
    secondary: '#2d5a4c'
  }
};

const createThemeFromColor = (color) => {
  if (color === DEFAULT_THEME.primary) {
    return DEFAULT_THEME;
  }

  const palette = colorPalettes[color] || colorPalettes.default;
  return {
    ...palette,
    background: adjustColor(palette.secondary, -20)
  };
};

// Fonction pour ajuster les couleurs
const adjustColor = (hex, amount) => {
  const color = hex.replace('#', '');
  const num = parseInt(color, 16);
  
  let r = (num >> 16) + amount;
  let g = ((num >> 8) & 0x00FF) + amount;
  let b = (num & 0x0000FF) + amount;
  
  r = Math.min(Math.max(0, r), 255);
  g = Math.min(Math.max(0, g), 255);
  b = Math.min(Math.max(0, b), 255);
  
  return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
};

export function ThemeProvider({ children }) {
  const [currentTheme, setCurrentTheme] = useState(DEFAULT_THEME);

  useEffect(() => {
    // Appliquer les styles directement sur le body
    document.body.style.setProperty('--primary-color', currentTheme.primary);
    document.body.style.setProperty('--secondary-color', currentTheme.secondary);
    document.body.style.setProperty('--background-color', currentTheme.background);
    
    // Appliquer le gradient et les styles de fond
    document.body.style.background = currentTheme.gradient;
    document.body.style.backgroundAttachment = 'fixed';  // Important pour éviter la répétition
    document.body.style.backgroundSize = 'cover';
    document.body.style.minHeight = '100vh';
    document.body.style.margin = '0';
    document.body.style.cursor = 'none';

    // Ajouter les styles de la scrollbar dynamique
    document.documentElement.style.setProperty('--scrollbar-color', currentTheme.primary);
    
    // Appliquer les styles de la scrollbar
    const style = document.createElement('style');
    style.textContent = `
      ::-webkit-scrollbar {
        width: 12px;
        background: rgba(0, 0, 0, 0.2);
      }

      ::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        margin: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background: ${currentTheme.primary};
        border-radius: 10px;
        border: 3px solid rgba(0, 0, 0, 0.2);
        background-clip: padding-box;
        min-height: 50px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: ${currentTheme.primary}ee;
        border: 2px solid rgba(0, 0, 0, 0.2);
      }

      * {
        scrollbar-width: thin;
        scrollbar-color: ${currentTheme.primary} rgba(0, 0, 0, 0.3);
      }
    `;

    // Supprimer l'ancien style s'il existe
    const oldStyle = document.getElementById('scrollbar-style');
    if (oldStyle) {
      oldStyle.remove();
    }

    // Ajouter le nouveau style avec un ID
    style.id = 'scrollbar-style';
    document.head.appendChild(style);

    return () => {
      // Nettoyage lors du démontage
      style.remove();
    };
  }, [currentTheme]);

  const updateTheme = (color) => {
    if (color === DEFAULT_THEME.primary) {
      setCurrentTheme(DEFAULT_THEME);
      return;
    }
    const newTheme = createThemeFromColor(color);
    setCurrentTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ currentTheme, updateTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeContext); 
import { createBrowserRouter, Outlet } from "react-router-dom"
import UploadPage from "./Pages/uploadPage/UploadPage"
import PrivateRoute from "./components/PrivateRoute"
import Logout from "./components/Logout"
import Header from "./components/Header"
import DrawerNav from "./components/DrawerNav/DrawerNav"
import HomePage from "./Pages/HomePage"
import { ErrorPage } from "./Pages/errors/ErroPage"
import { Versus } from "./Pages/VersusPage/Versus"
import { ProfilPage } from "./Pages/ProfiPage/PageProfil"
import { UltraLight } from "./Pages/UltraPage/ultraLight"
import { FpsPage } from "./Pages/Categories/FpsPage"
import { MobaPage } from "./Pages/Categories/MobaPage"
import { BattleRoyalePage } from "./Pages/Categories/BattleRoyalePage"
import { CoursePage } from "./Pages/Categories/CoursePage"
import { CombatPage } from "./Pages/Categories/CombatPage"
import { CasinoPage } from "./Pages/Categories/CasinoPage"
import { RpgPage } from "./Pages/Categories/RpgPage"
import { MmoPage } from "./Pages/Categories/MmoPage"
import { StrategiePage } from "./Pages/Categories/StrategiePage"
import { SportsPage } from "./Pages/Categories/SportsPage"
import { RetroPage } from "./Pages/Categories/RetroPage"
import { ArcadePage } from "./Pages/Categories/ArcadePage"
import { MobilePage } from "./Pages/Categories/MobilePage"
import { AventurePage } from "./Pages/Categories/AventurePage"
import { SimulationPage } from "./Pages/Categories/SimulationPage"

export default function getRouter() {
  return createBrowserRouter([
    {
      path: '/',
      errorElement: <ErrorPage />,
      element: 
        <DrawerNav>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              width: '100%',
              height: '100%',
            }}
          >
            <Header />
            <div
              id="main-content"
              style={{
                padding: '20px',
                height: '100%',
                overflow: 'auto',
              }}
            >
              <Outlet />
            </div>
          </div>
        </DrawerNav>
      ,
      children: [
        {
          path: '/',
          element: <HomePage />
        },
        {
          path: '/upload',
          element:
          <PrivateRoute>
            <UploadPage />
          </PrivateRoute>
        },
        {
          path: '/login',
          element: <PrivateRoute />
        },
        {
          path: '/logout',
          element: <Logout />
        },
        {
          path: '/ultra',
          element: 
            <PrivateRoute>
              <UltraLight/>
            </PrivateRoute>
        },
        {
          path: '/versus', 
          element: 
            <PrivateRoute>
              <Versus/>
            </PrivateRoute>
        }, 
        {
          // path: '/my-ligher-profile',
          path: '/lighter-profile/:username',
          element:
            <PrivateRoute>
              <ProfilPage/>
            </PrivateRoute>
        },
        {
          path: '/categories/fps',
          element: <FpsPage />
        },
        {
          path: '/categories/moba',
          element: <MobaPage />
        },
        {
          path: '/categories/battle-royale',
          element: <BattleRoyalePage />
        },
        {
          path: '/categories/course',
          element: <CoursePage />
        },
        {
          path: '/categories/combat',
          element: <CombatPage />
        },
        {
          path: '/categories/casino',
          element: <CasinoPage />
        },
        {
          path: '/categories/rpg',
          element: <RpgPage />
        },
        {
          path: '/categories/mmo',
          element: <MmoPage />
        },
        {
          path: '/categories/strategie',
          element: <StrategiePage />
        },
        {
          path: '/categories/sports',
          element: <SportsPage />
        },
        {
          path: '/categories/retro',
          element: <RetroPage />
        },
        {
          path: '/categories/arcade',
          element: <ArcadePage />
        },
        {
          path: '/categories/mobile',
          element: <MobilePage />
        },
        {
          path: '/categories/aventure',
          element: <AventurePage />
        },
        {
          path: '/categories/simulation',
          element: <SimulationPage />
        }
      ]
    }
  ]);
}

import { createContext, useContext, useEffect, useMemo, useState } from "react"
import Keycloak from "keycloak-js"
import { addAxiosKcInterceptor } from "../services/axios"
import PropsTypes from "prop-types"

// keycloak context
const KeycloakContext = createContext()

// keycloak provider component
export const KeycloakProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [keycloak, setKeycloak] = useState(null)
  
  useEffect(() => {
    // check if keycloak is already initialized
    if (keycloak === null) {
      const kc = new Keycloak({
        url: process.env.REACT_APP_KEYCLOAK_URL,
        realm: process.env.REACT_APP_KEYCLOAK_REALM,
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        useNonce: true
      })

      // initialize keycloak instance
      kc.init({
        onLoad: 'check-sso', // checks if user is already logged in if not continue as anonymous
        checkLoginIframe: true,
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html', // check sso without redirect uri
        silentCheckSsoFallback: false, // silent check sso disable fallback
        pkceMethod: 'S256',
      })
      .then((auth) => {
        if (!auth) {
          setIsAuthenticated(false)
        } else {
          addAxiosKcInterceptor(kc)
          setIsAuthenticated(true)
        }
        setKeycloak(kc)
      })
      .catch((error) => {
        setIsAuthenticated(false);
        console.error("Keycloak failed to initialize: ", error)
        alert("Authentication is currently unavailable.")
      })
    }
  }, [keycloak])

  const contextValue = useMemo(() => ({
    isAuthenticated,
    kc: keycloak,
    user : keycloak?.tokenParsed || {}
  }), [isAuthenticated, keycloak]);

  return (
    <KeycloakContext.Provider value={contextValue}>
      {children}
    </KeycloakContext.Provider>
  )
}

// keycloak provider props
KeycloakProvider.propTypes = {
  children: PropsTypes.node.isRequired
}

// Custom hook to use Keycloak instance
export const useKeycloak = () => {
  return useContext(KeycloakContext);
};

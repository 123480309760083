import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../context/keycloakContext";
import HighlightBattle from '../components/Feed/HighlightBattle';
import { Api } from '../services/Api';
import { Typography, Grid, Card, CardMedia, CardContent, Box } from '@mui/material';
import VideoCarousel from '../components/VideoCarousel/VideoCarousel';
import CategoryChips from '../components/CategoryChips/CategoryChips';
import GlassBox from '../components/GlassBox/GlassBox';

export default function HomePage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);
  const [featuredHighlights, setFeaturedHighlights] = useState([
    {
      title: "Pedro en fire",
      game: "Valorant",
      image: "https://assets.xboxservices.com/assets/4e/bc/4ebcb533-e184-42f3-833b-9aa47a81f39e.jpg?n=153142244433_Poster-Image-1084_1920x720.jpg",
      votes: 124,
      lighter: "Pedro en bien"
    },
    {
      title: "1v5 sniper Comeback",
      game: "Call of Duty : Black Ops 6",
      image: "https://imgs.callofduty.com/content/dam/atvi/callofduty/cod-touchui/blackops6/meta/BO6_LP-meta_image.jpg",
      votes: 89,
      lighter: "Nooby"
    },
    {
      title: "saut en quad du rooftop + 9 kills",
      game: "Battlefield 2042",
      image: "https://level-1.fr/Principal/wp-content/uploads/2021/06/EGS_Battlefield2042_DICE_S1_2560x1440-36f16374c9c29a18a46872795b483d72.jpg",
      votes: 256,
      lighter: "CaloorBaby"
    }
  ]);

  // Données pour le carrousel de vidéos
  const carouselVideos = [
    {
      id: 1,
      title: "No way",
      description: "Un 1v5 épique en ranked Immortal",
      videoUrl: "https://deb97nn18a620.cloudfront.net/9139c098-e026-4ea6-83f2-f80c49d72d33-1730593662942-dolce%20vita.mp4",
      game: "Call of Duty : Modern Warfare 2"
    },
    {
      id: 2,
      title: "dédicace a personne fallait etre la",
      description: "Une action décisive en fin de partie",
      videoUrl: "https://deb97nn18a620.cloudfront.net/9139c098-e026-4ea6-83f2-f80c49d72d33-1730593606365-flaich.mp4",
      game: "Call of Duty : Modern Warfare 2"
    },
    {
      id: 3,
      title: "CS2 Pro Play",
      description: "Les meilleurs moments du Major",
      videoUrl: "https://deb97nn18a620.cloudfront.net/9139c098-e026-4ea6-83f2-f80c49d72d33-1730749330899-uspAce.mp4",
      game: "Counter-Strike 2"
    }
  ];

  // récupération des paires de vidéos, je reverrais plus tard les algo de vidéos
  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(3);
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des vidéos', error);
      }
    };
    fetchVideoPairs();
  }, []);

  const handleCategoryClick = (category) => {
    console.log('Catégorie sélectionnée:', category);
    // Ici vous pourrez ajouter la logique de filtrage selon la catégorie
  };

  return (
    <GlassBox>
      <VideoCarousel videos={carouselVideos} autoPlayInterval={60000} />
      <CategoryChips onCategoryClick={handleCategoryClick} />
      
      <Typography variant="h4" sx={{ mb: 3, color: 'white' }}>
        {t('Les meilleurs intéractions')}
      </Typography>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {featuredHighlights.map((highlight, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card 
              sx={{ 
                bgcolor: 'rgba(255,255,255,0.1)', 
                backdropFilter: 'blur(10px)',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.4)'
                }
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={highlight.image}
                alt={highlight.title}
                sx={{
                  objectFit: 'cover',
                }}
              />
              <CardContent>
                <Typography variant="h6" color="white" gutterBottom>
                  {highlight.title}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  gap: 1
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center'
                  }}>
                    <Typography variant="body2" color="gray">
                      {highlight.game}
                    </Typography>
                  </Box>
                  
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center'
                  }}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: '#ad5389',
                        fontStyle: 'italic'
                      }}
                    >
                      {t('Par')} {highlight.lighter}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: '#4dfff4',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5
                      }}
                    >
                      {highlight.votes} {t('votes')}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Latest Battles */}
      <Typography variant="h4" sx={{ mb: 3, color: 'white' }}>
        {t('Arènes')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <HighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
            />
          ))
        ) : (
          <Typography color="gray">{t('Chargement des battles...')}</Typography>
        )}
      </Box>
    </GlassBox>
  );
}

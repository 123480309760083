import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Api } from '../../services/Api';
import { 
  Box, 
  List, 
  ListItem, 
  Typography, 
  Paper,
  Chip,
  Container
} from '@mui/material';
import CustomPlayer from '../../Player/Player';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/material/styles';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

// Animation de pulsation pour le podium
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
`;

// Animation de brillance
const shine = keyframes`
  0% { background-position: 200% center; }
  100% { background-position: -200% center; }
`;

// Animation pour le reflet
const shimmer = keyframes`
  0% {
    left: -150%;
  }
  100% {
    left: 150%;
  }
`;

// Style raffiné pour le podium avec effet de reflet amélioré
const PodiumPaper = styled(Paper)(({ theme, position }) => ({
  background: position <= 3 
    ? 'linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(128,0,128,0.15) 100%)'
    : 'linear-gradient(135deg, rgba(30,30,45,0.3) 0%, rgba(60,60,80,0.1) 100%)',
  backdropFilter: 'blur(12px)',
  border: position <= 3 
    ? '1px solid rgba(255,255,255,0.2)'
    : '1px solid rgba(255,255,255,0.1)',
  borderRadius: '16px',
  boxShadow: position <= 3 
    ? `0 8px 32px rgba(128,0,128,0.15)`
    : '0 4px 12px rgba(0,0,0,0.08)',
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  position: 'relative',
  overflow: 'hidden',
  animation: position <= 3 ? `${pulse} 3s infinite ease-in-out` : 'none',
  animationDelay: `${position * 0.2}s`,

  // Effet de reflet amélioré
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '-150%',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent)',
    transform: 'skewX(-20deg)',
    filter: 'blur(3px)',
    opacity: 0.7,
    transition: 'none',
  },

  '&:hover': {
    transform: 'translateY(-5px)',
    '&::before': {
      animation: `${shimmer} 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite`,
      animationDelay: '0.5s',
      animationDuration: '2s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
      animationPlayState: 'running',
    }
  },

  // Arrête l'animation quand on quitte le hover mais garde la position
  '&:not(:hover)::before': {
    animationPlayState: 'paused',
    left: '-150%',
  }
}));

// Style amélioré pour la section vidéo avec animation synchronisée
const VideoSection = styled(Box)(({ position }) => ({
  width: '600px',
  position: 'relative',
  margin: '0 auto',
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
}));

// Style pour la vidéo avec l'animation principale (une seule déclaration)
const VideoWrapper = styled(Box)(({ position }) => ({
  width: '600px',
  borderRadius: '12px',
  overflow: 'hidden',
  border: position <= 3 
    ? `2px solid ${getMedalColor(position)}40`
    : '1px solid rgba(255,255,255,0.1)',
  background: 'rgba(0,0,0,0.2)',
  boxShadow: position <= 3 
    ? `0 0 30px ${getMedalColor(position)}20`
    : 'none',
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  position: 'relative',
  margin: '0 auto',

  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: position <= 3 
      ? `0 10px 30px ${getMedalColor(position)}30`
      : '0 10px 30px rgba(0,0,0,0.2)',
    
    // Animation des éléments enfants au hover
    '& + .video-overlay, & ~ .video-overlay': {
      transform: 'scale(1.02)',
    },
    '& ~ .game-badge': {
      transform: 'scale(1.02)',
    }
  }
}));

// Style amélioré pour les stats
const StatsBox = styled(Box)(({ color }) => ({
  background: 'rgba(0,0,0,0.2)',
  borderRadius: '10px',
  padding: '8px 16px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  border: '1px solid rgba(255,255,255,0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'rgba(0,0,0,0.3)',
    transform: 'translateY(-2px)',
    boxShadow: `0 5px 15px ${color}20`
  }
}));

// Style raffiné pour les chips
const StyledChip = styled(Chip)(({ color }) => ({
  background: `linear-gradient(45deg, ${color}cc, ${color}99)`,
  color: 'white',
  border: '1px solid rgba(255,255,255,0.1)',
  padding: '0 12px',
  height: '24px',
  fontSize: '0.8rem',
  transition: 'all 0.3s ease',
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: `0 0 15px ${color}40`,
  }
}));

// Style pour les médailles
const getMedalColor = (position) => {
  switch(position) {
    case 1: return '#FFD700'; // Or
    case 2: return '#C0C0C0'; // Argent
    case 3: return '#CD7F32'; // Bronze
    default: return 'transparent';
  }
};

// Style pour l'overlay des informations
const VideoInfo = styled(Box)(({ position }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0.5), transparent)',
  padding: '20px 15px 15px',
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  zIndex: 2,
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  transform: 'scale(1)',
  transformOrigin: 'bottom'
}));

// Style amélioré pour le badge du jeu
const GameBadge = styled(Box)(({ position }) => ({
  position: 'absolute',
  top: 15,
  right: 15,
  background: 'rgba(0,0,0,0.8)',
  padding: '8px 12px',
  borderRadius: '8px',
  backdropFilter: 'blur(4px)',
  border: '1px solid rgba(255,255,255,0.1)',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  transform: 'scale(1)',
  transformOrigin: 'top right'
}));

export function UltraLight() {
  const [rankings, setRankings] = useState([]);
  const { t } = useTranslation('app');

  useEffect(() => {
    const fetchRankings = async () => {
      const data = await Api.getUltra();
      setRankings(data);
    };
    fetchRankings();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography 
        variant="h4" 
        gutterBottom 
        align="center"
        sx={{ 
          mb: 4,
          background: 'linear-gradient(45deg, #800080, #ff69b4, #800080)',
          backgroundSize: '200% auto',
          backgroundClip: 'text',
          textFillColor: 'transparent',
          fontWeight: 'bold',
          animation: `${shine} 3s infinite linear`,
        }}
      >
        {t('TOP LIGHT MONDE')}
      </Typography>

      <List sx={{ maxWidth: 900, mx: 'auto' }}>
        {rankings.map((rank, index) => (
          <PodiumPaper key={index} position={index + 1}>
            <ListItem sx={{ 
              px: 0, 
              flexDirection: 'column',
              gap: 2
            }}>
              {/* En-tête avec rang et nom */}
              <Box sx={{ 
                display: 'flex', 
                width: '100%',
                alignItems: 'center',
                gap: 3
              }}>
                {/* Position et Trophy */}
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5
                }}>
                  {index < 3 && (
                    <EmojiEventsIcon 
                      sx={{ 
                        fontSize: 32, 
                        color: getMedalColor(index + 1),
                        opacity: 0.9
                      }} 
                    />
                  )}
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      fontWeight: 600,
                      color: 'white',
                      opacity: 0.9
                    }}
                  >
                    {index + 1}
                  </Typography>
                </Box>

                {/* Infos utilisateur */}
                <Box sx={{ 
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                  <Typography variant="h6" sx={{ 
                    fontWeight: 500,
                    color: index < 3 ? getMedalColor(index + 1) : 'white',
                    opacity: 0.95,
                    textTransform: 'uppercase',
                    letterSpacing: '0.5px'
                  }}>
                    {rank.username}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <StyledChip 
                      label={`${rank.totalVotes} votes`}
                      color="#800080"
                    />
                    <StyledChip 
                      label={`${rank.winRate.toFixed(1)}% win`}
                      color="#4a148c"
                    />
                  </Box>
                </Box>
              </Box>

              {/* Titre de la vidéo avec style amélioré */}
              <Typography 
                variant="h6" 
                sx={{ 
                  color: 'white',
                  opacity: 0.9,
                  fontWeight: 500,
                  textAlign: 'center',
                  mb: 2,
                  background: index < 3 ? `linear-gradient(45deg, ${getMedalColor(index + 1)}80, white)` : undefined,
                  backgroundClip: index < 3 ? 'text' : undefined,
                  textFillColor: index < 3 ? 'transparent' : undefined,
                }}
              >
                {rank.videoTitle}
              </Typography>

              {/* Vidéo agrandie */}
              <VideoSection position={index + 1}>
                <GameBadge position={index + 1} className="game-badge">
                  <SportsEsportsIcon sx={{ 
                    fontSize: 18, 
                    color: index < 3 ? getMedalColor(index + 1) : 'white' 
                  }} />
                  <Box>
                    <Typography sx={{ 
                      color: 'white',
                      fontSize: '0.8rem',
                      fontWeight: 500,
                      opacity: 0.7,
                      textTransform: 'uppercase',
                      letterSpacing: '0.5px'
                    }}>
                      {rank.game.name}
                    </Typography>
                    <Typography sx={{ 
                      color: 'white',
                      fontSize: '0.9rem',
                      fontWeight: 600
                    }}>
                      {rank.game.type}
                    </Typography>
                  </Box>
                </GameBadge>

                <VideoWrapper position={index + 1}>
                  <CustomPlayer 
                    src={rank.videoUrl}
                    layoutType="ultraMinimal"
                  />
                </VideoWrapper>

                <VideoInfo position={index + 1} className="video-overlay">
                  <Box>
                    <Typography sx={{ 
                      color: 'white',
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      textTransform: 'uppercase',
                      mb: 1,
                      textShadow: '0 2px 4px rgba(0,0,0,0.3)'
                    }}>
                      {rank.username}
                    </Typography>
                    <Typography sx={{ 
                      color: 'rgba(255,255,255,0.8)',
                      fontSize: '0.9rem',
                      fontWeight: 500
                    }}>
                      {rank.videoTitle}
                    </Typography>
                  </Box>
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'flex-end',
                    gap: 1 
                  }}>
                    <Typography sx={{ 
                      color: getMedalColor(index + 1) || '#fff',
                      fontWeight: 'bold',
                      fontSize: '1.2rem'
                    }}>
                      #{index + 1}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <StyledChip 
                        label={`${rank.totalVotes} votes`}
                        color="#800080"
                        size="small"
                      />
                      <StyledChip 
                        label={`${rank.winRate.toFixed(1)}% win`}
                        color="#4a148c"
                        size="small"
                      />
                    </Box>
                  </Box>
                </VideoInfo>
              </VideoSection>
            </ListItem>
          </PodiumPaper>
        ))}
      </List>
    </Container>
  );
}
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { GpsFixed, Timer, EmojiEvents, Whatshot } from '@mui/icons-material';
import battleRoyaleHeader from '../../assets/headerCategoriesPhotos/br.jpg';

export function BattleRoyalePage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const brInfoCards = [
    {
      icon: <GpsFixed />,
      title: 'Clutch Royale',
      desc: 'Les derniers cercles les plus intenses'
    },
    {
      icon: <Timer />,
      title: 'Survie Extrême',
      desc: 'Des retournements de situation incroyables'
    },
    {
      icon: <EmojiEvents />,
      title: 'Victory Royale',
      desc: 'Les finales les plus spectaculaires'
    },
    {
      icon: <Whatshot />,
      title: 'Éliminations Épiques',
      desc: 'Les meilleures séquences d\'action'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="BATTLE ROYALE ARENA"
        description="Entrez dans l'arène du Battle Royale où seul le meilleur survit. Des clutchs impossibles aux victoires royales épiques, votez pour les moments les plus mémorables."
        color="#ffa502"
        backgroundImage={battleRoyaleHeader}
        infoCards={brInfoCards}
      />

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#ffa502"
              buttonGradient={{
                from: '#ffa502',
                to: '#8b4513'
              }}
              hoverShadowColor="rgba(255, 165, 2, 0.5)"
              vsColor="#ffa502"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
} 
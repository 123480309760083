import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Tabs,
  Tab,
  useTheme,
  IconButton
} from "@mui/material"
import AvatarMenu from "./AvatarMenu"
import { useKeycloak } from "../context/keycloakContext";
import { Search } from '@mui/icons-material';
import LangSelector from "./langSelector";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { SearchButton } from "./CustomComponents";
import SearchBackDrop from "./SearchBackDrop";
import { useState } from "react";
import {
  SportsEsportsOutlined,
  GpsFixed,
  SportsCricket,
  SportsMotorsports,
  Casino,
  SportsScore,
  SportsEsports,
  Sports,
  Extension,
  Psychology,
  Public,
  ChevronLeft,
  ChevronRight,
  Gamepad,
  PhoneAndroid,
  Explore,
  AutoStories,
  Science
} from '@mui/icons-material';
import styled from '@emotion/styled';
import NavBarCat from './NavBarCat';
import { useTheme as useCustomTheme, DEFAULT_THEME } from '../context/ThemeContext';

const ScrollContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '700px',
  overflow: 'hidden',
  margin: '0 auto',
}));

const ScrollButton = styled(IconButton)(({ direction }) => ({
  position: 'absolute',
  [direction]: 0,
  zIndex: 2,
  color: 'rgba(255, 255, 255, 0.6)',
  background: 'linear-gradient(90deg, rgba(173,83,137,0.1), transparent)',
  backdropFilter: 'blur(4px)',
  height: '100%',
  width: '30px',
  padding: 0,
  borderRadius: direction === 'left' ? '8px 0 0 8px' : '0 8px 8px 0',
  transition: 'all 0.3s ease',
  opacity: 0.4,
  '&:hover': {
    background: 'linear-gradient(90deg, rgba(173,83,137,0.2), transparent)',
    opacity: 1,
    width: '35px',
    color: 'rgba(255, 255, 255, 0.9)',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1rem',
    transition: 'transform 0.3s ease',
  },
  '&:hover .MuiSvgIcon-root': {
    transform: 'scale(1.2)',
  }
}));

const settings = [
  {
    text: 'settings',
    route: '/settings'
  },
  {
    text: 'profile',
    route: '/profile'
  }
]

export default function Header() {
  const { isAuthenticated, kc } = useKeycloak();
  const { t: tCommon } = useTranslation('app');

  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const { currentTheme } = useCustomTheme();

  const handleToggle = () => {
    setOpen(!open);
  }

  const handleScroll = (direction) => {
    const tabsNode = document.querySelector('.MuiTabs-scroller');
    if (tabsNode) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      tabsNode.scrollLeft += scrollAmount;
    }
  };

  const categories = [
    { label: 'FPS', icon: <GpsFixed />, color: '#ff4757' },
    { label: 'MOBA', icon: <SportsCricket />, color: '#2ed573' },
    { label: 'Battle Royale', icon: <SportsScore />, color: '#ffa502' },
    { label: 'Racing', icon: <SportsMotorsports />, color: '#1e90ff' },
    { label: 'Fighting', icon: <SportsEsportsOutlined />, color: '#ff6b81' },
    { label: 'Card Games', icon: <Casino />, color: '#a4b0be' },
    { label: 'RPG', icon: <Psychology />, color: '#8e44ad' },
    { label: 'MMO', icon: <Public />, color: '#2ecc71' },
    { label: 'Strategy', icon: <Extension />, color: '#e67e22' },
    { label: 'Sports', icon: <Sports />, color: '#3498db' },
    { label: 'Retro', icon: <SportsEsports />, color: '#e84393' },
    { label: 'Arcade', icon: <Gamepad />, color: '#00b894' },
    { label: 'Mobile', icon: <PhoneAndroid />, color: '#6c5ce7' },
    { label: 'Adventure', icon: <Explore />, color: '#00cec9' },
    { label: 'Visual Novel', icon: <AutoStories />, color: '#fd79a8' },
    { label: 'Simulation', icon: <Science />, color: '#55efc4' }
  ];

  return (
    <>
      <SearchBackDrop open={open} handleToggle={handleToggle} />
      <AppBar
        elevation={1}
        position="sticky"
        sx={{
          background: `radial-gradient(ellipse at center 150%, ${currentTheme.primary}80 0%, transparent 70%)`,
          minHeight: '110px',
          width: '100%',
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            paddingRight: '10px',
            paddingLeft: '10px',
            justifyContent: 'space-between',
            gap: 1,
            flexDirection: 'column',
            height: '100%',
            pt: 1.5,
            pb: 1.5,
          }}
        >
          <Typography 
            variant="h4"
            sx={{ 
              color: 'white',
              fontWeight: 700,
              letterSpacing: '3px',
              textTransform: 'uppercase',
              background: currentTheme === DEFAULT_THEME 
                ? `linear-gradient(45deg, 
                    #2D1A60 0%,
                    ${currentTheme.primary} 35%,
                    #A44EE4 50%,
                    ${currentTheme.primary} 65%,
                    #2D1A60 100%
                  )`
                : `linear-gradient(45deg, ${currentTheme.primary}, ${currentTheme.secondary})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textShadow: '0 2px 4px rgba(0,0,0,0.2)',
              mb: 1.5,
              fontFamily: '"Rajdhani", sans-serif',
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -4,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '60px',
                height: '2px',
                background: `linear-gradient(90deg, 
                  transparent,
                  rgba(255, 255, 255, 0.8),
                  transparent
                )`,
                boxShadow: '0 0 8px rgba(255, 255, 255, 0.4)',
              }
            }}
          >
            HIGHLIGHTS
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            width: '100%', 
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2
          }}>
            <NavBarCat />

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <SearchButton 
                variant="outlined"
                onClick={handleToggle}
                sx={{
                  height: '35px',
                  gap: 1,
                  background: 'linear-gradient(135deg, rgba(15, 85, 232, 0.1) 0%, rgba(157, 223, 243, 0.1) 100%)',
                  color: 'white',
                  borderRadius: '30px',
                  borderWidth: '1px',
                  border: '1px solid #800080',
                  "&:hover": {
                    transform: 'translateY(-1px)',
                    boxShadow: '0 5px 60px rgba(104, 8, 213, 0.5)'
                  }
                }}
              >
                <Typography variant="h7" sx={{color: "white", fontFamily: 'Exo, sans-serif'}}>
                  {tCommon('search')}
                </Typography>
                <Search sx={{color: "white", float: 'right'}} />
              </SearchButton>

              {isAuthenticated ? (
                <AvatarMenu settings={settings} />
              ) : (
                <Button
                  type={"primary"}
                  onClick={() => kc?.login({ redirectUri: window.location.origin })}
                >
                  {tCommon('login')}
                </Button>
              )}
              <LangSelector />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
    
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomPlayer from '../../Player/Player';
import { Box, Typography, Button, Fade, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import PersonIcon from '@mui/icons-material/Person';
import styles from './LargeHighlightBattle.module.css';
import { useTranslation } from "react-i18next";
import { Api } from '../../services/Api';

export default function LargeHighlightBattle({ 
  videoPair, 
  matchId,
  borderColor = '#800080',
  buttonGradient = {
    from: '#ad5389',
    to: '#3c1053'
  },
  hoverShadowColor = 'rgba(104, 8, 213, 0.5)',
  vsColor = '#800080'
}) {
  const { t } = useTranslation('app');
  const [voted, setVoted] = useState(false);
  const [selected, setSelected] = useState(null);
  const [notification, setNotification] = useState({ show: false, message: '' });

  if (!Array.isArray(videoPair) || videoPair.length !== 2) {
    console.error('Invalid videoPair:', videoPair);
    return null;
  }

  console.log('LargeHighlightBattle Props:', { 
    videoPair, 
    matchId, 
    video1: videoPair[0], 
    video2: videoPair[1] 
  });

  const handleVote = async (index) => {
    try {
      const response = await Api.voteForHighlight(matchId, index + 1);
      
      if (response && response.success) {
        setVoted(true);
        setSelected(index);
      } else {
        setNotification({
          show: true,
          message: "Ce match n'est plus disponible"
        });
        
        setTimeout(() => {
          setNotification({ show: false, message: '' });
        }, 3000);
      }
    } catch (error) {
      console.error('Erreur lors du vote:', error);
    }
  };

  const VideoBox = ({ video, index }) => (
    <Box 
      className={styles.videoContainer}
      sx={{
        position: 'relative',
        transition: 'all 0.3s ease',
        borderRadius: '12px',
        overflow: 'hidden',
        padding: '8px',
        background: 'rgba(0,0,0,0.2)',
        border: !voted ? 
          `4px solid ${borderColor}30` : 
          selected === index ? 
            '2px solid #FFD700' : 
            '2px solid transparent',
        boxShadow: selected === index ?
          '0 0 20px rgba(255, 215, 0, 0.2)' : 
          `0 0 15px ${borderColor}10`,
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: `0 5px 60px ${hoverShadowColor}`
        }
      }}
    >
      <Box
        onClick={() => {
          console.log(`Navigate to ${video.uploader}'s profile`);
        }}
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: '6px 14px',
          background: 'linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,0.6))',
          borderRadius: '30px',
          transition: 'all 0.3s ease',
          cursor: 'pointer',
          '&:hover': {
            background: selected === index ?
              'linear-gradient(90deg, rgba(173, 83, 137, 0.2), rgba(60, 16, 83, 0.4))' :
              'linear-gradient(90deg, rgba(0,0,0,0.2), rgba(0,0,0,0.8))',
            transform: 'translateY(-2px)',
            '& .lighter-name::after': {
              transform: 'scaleX(1)',
            }
          },
        }}
      >
        <Box
          sx={{
            width: '22px',
            height: '22px',
            borderRadius: '50%',
            background: selected === index ? 
              'linear-gradient(45deg, #ad5389, #3c1053)' : 
              'rgba(255, 255, 255, 0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <PersonIcon 
            sx={{ 
              fontSize: '0.85rem',
              color: 'white',
            }} 
          />
        </Box>
        <Typography
          className="lighter-name"
          sx={{
            color: selected === index ? '#FFD700' : 'white',
            fontWeight: 600,
            fontSize: '0.85rem',
            letterSpacing: '0.5px',
            textTransform: 'uppercase',
            textShadow: '0 2px 4px rgba(0,0,0,0.3)',
            transition: 'all 0.3s ease',
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: -2,
              left: 0,
              width: '100%',
              height: '2px',
              background: selected === index ?
                'linear-gradient(90deg, #ad5389, transparent)' :
                'linear-gradient(90deg, rgba(255,255,255,0.5), transparent)',
              transform: 'scaleX(0)',
              transformOrigin: 'left',
              transition: 'transform 0.3s ease',
            }
          }}
        >
          {video.uploader}
        </Typography>
      </Box>

      <Box sx={{ 
        width: '100%',
        height: '100%',
        '& > div': {
          height: '100%',
          width: '100%',
        }
      }}>
        <CustomPlayer layoutType="minimalControls" src={video.url} />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          background: 'linear-gradient(to top, rgba(0,0,0,0.9), transparent)',
          padding: '20px 16px 12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          transition: 'all 0.3s ease',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography 
            variant="h6" 
            sx={{
              color: selected === index ? '#FFD700' : 'white',
              fontWeight: 'bold',
              fontSize: '1.2rem',
              marginBottom: '8px',
              textShadow: '0 2px 4px rgba(0,0,0,0.5)',
              transition: 'color 0.3s ease',
            }}
          >
            {video.title}
          </Typography>
          <Box
            sx={{
              display: 'inline-block',
              background: selected === index ? 
                'linear-gradient(45deg, rgba(255,215,0,0.2), rgba(255,165,0,0.2))' : 
                'rgba(0,0,0,0.6)',
              padding: '4px 12px',
              borderRadius: '15px',
              border: selected === index ? 
                '1px solid rgba(255,215,0,0.3)' : 
                '1px solid rgba(255,255,255,0.1)',
            }}
          >
            <Typography
              sx={{
                color: selected === index ? '#FFD700' : 'rgba(255,255,255,0.9)',
                fontSize: '0.85rem',
                fontWeight: 500,
              }}
            >
              {video.game}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ marginLeft: 2 }}>
          {!voted ? (
            <Tooltip title={t('Lighter cette vidéo')} placement="left">
              <Button
                variant="contained"
                onClick={() => handleVote(index)}
                sx={{
                  background: `linear-gradient(45deg, ${buttonGradient.from}, ${buttonGradient.to})`,
                  borderRadius: '20px',
                  padding: '8px 20px',
                  '&:hover': {
                    background: `linear-gradient(45deg, ${buttonGradient.from}CC, ${buttonGradient.to}CC)`,
                    transform: 'translateY(-2px)',
                  },
                  transition: 'all 0.3s ease',
                }}
                startIcon={<HowToVoteIcon />}
              >
                {t('Voter')}
              </Button>
            </Tooltip>
          ) : (
            selected === index && (
              <Fade in={true}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    background: 'linear-gradient(45deg, #FFD700, #FFA500)',
                    padding: '8px 20px',
                    borderRadius: '20px',
                    boxShadow: '0 0 20px rgba(255, 215, 0, 0.3)',
                  }}
                >
                  <CheckIcon 
                    sx={{ 
                      color: 'white',
                      animation: 'bounce 0.5s ease',
                      '@keyframes bounce': {
                        '0%': { transform: 'scale(0)' },
                        '50%': { transform: 'scale(1.2)' },
                        '100%': { transform: 'scale(1)' },
                      },
                    }} 
                  />
                  <Typography sx={{ color: 'white', fontWeight: 'bold' }}>
                    {t('Light !')}
                  </Typography>
                </Box>
              </Fade>
            )
          )}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box className={styles.highlightBattle}>
      {notification.show && (
        <Fade in={notification.show}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000,
              background: 'linear-gradient(45deg, #ad5389, #3c1053)',
              padding: '16px 32px',
              borderRadius: '12px',
              boxShadow: '0 4px 20px rgba(173, 83, 137, 0.3)',
              animation: 'fadeInUp 0.5s ease',
              '@keyframes fadeInUp': {
                from: {
                  opacity: 0,
                  transform: 'translate(-50%, 20px)',
                },
                to: {
                  opacity: 1,
                  transform: 'translate(-50%, -50%)',
                },
              },
            }}
          >
            <Typography
              sx={{
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '1.1rem',
                textShadow: '0 2px 4px rgba(0,0,0,0.2)',
              }}
            >
              {notification.message}
            </Typography>
          </Box>
        </Fade>
      )}
      <VideoBox video={videoPair[0]} index={0} />
      <Box className={styles.vsContainer}>
        <Typography 
          className={styles.vsText}
          sx={{ color: vsColor }}
        >
          VS
        </Typography>
        <Box 
          className={styles.divider}
          sx={{ backgroundColor: vsColor }}
        />
      </Box>
      <VideoBox video={videoPair[1]} index={1} />
    </Box>
  );
}

LargeHighlightBattle.propTypes = {
  videoPair: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      uploader: PropTypes.string.isRequired,
      game: PropTypes.string.isRequired,
    })
  ).isRequired,
  matchId: PropTypes.number.isRequired,
  borderColor: PropTypes.string,
  buttonGradient: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string
  }),
  hoverShadowColor: PropTypes.string,
  vsColor: PropTypes.string
}; 
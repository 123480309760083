import { axiosInstance } from "./axios"

export const Api = {
  getFilVideos: async () => {
    const data = await axiosInstance.get("/videos")
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting videos", error)
        return []
      })
    return data
  },
  uploadVideo: async (formData) => {
    const response = (await axiosInstance.post("/upload",
      formData
      , {
        headers: {
          "Content-Type": "multipart/form-data;",
        }
      }
    ))
    return response.data
  },
  getGameLicenses: async () => {
    const data = await axiosInstance.get("/game-licenses")
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting game licenses", error)
        return {}
      })
    return data
  },
  getUltra: async () => {
    const data = await axiosInstance.get("/ultra")
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting ultra rankings", error)
        return []
      })
    return data
  },
  getVersus: async () => {
    const data = await axiosInstance.get("/versus")
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting versus videos", error)
        return []
      })
    return data 
  },
  getRandomVideos: async () => {
    const data = await axiosInstance.get("/random-videos")
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting random videos", error)
        return []
      })
    return data
  },
  // "getMultipleRandomVideos" méthode prend un paramètre pairCount qui indique le nombre de paires de vidéos
  // à récupérer. ca va effectue des appels répétés à getRandomVideos 
  // et accumule les paires dans un tableau videoPairs
  getMultipleRandomVideos: async (pairCount) => {
    const videoPairs = []
    for (let i = 0; i < pairCount; i++) {
      const data = await Api.getRandomVideos()
      if (data.videos && data.videos.length === 2) {
        // Créez un objet avec les vidéos et le matchId
        const pairWithMatchId = {
          videos: data.videos,
          matchId: data.matchId
        }
        videoPairs.push(pairWithMatchId)
      }
    }
    return videoPairs
  },
  /**
   * getUserHighlights
   * 
   * @param string userId 
   * @returns 
   */
  getUserHighlights: async (userId) => {
    const data = await axiosInstance.get(`/user-videos/${userId}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting user videos", error)
        return []
      })
    return data
  },

  /*********** USER requests *************/

  /**
   * getUserProfile
   * 
   * @param string userId 
   * @returns 
   */
  getUserProfile: async (userId) => {
    const data = await axiosInstance.get(`/user-profile/${userId}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting user profile", error)
        return null
      })
    return data
  },
  searchUsers: async (searchTerm) => {
    const data = await axiosInstance.get(`/search-users/${searchTerm}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error searching users", error)
        return []
      })
    return data
  },
  voteForHighlight: async (matchId, videoNumber) => {
    try {
      const response = await axiosInstance.post('/vote-highlight', {
        matchId,
        videoNumber
      });
      return response.data;
    } catch (error) {
      console.error("Erreur lors du vote:", error);
      return null;
    }
  },
  getUserBattles: async (userId) => {
    const data = await axiosInstance.get(`/user-battles/${userId}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting user battles", error)
        return []
      })
    return data
  },
  getUserRanking: async (userId) => {
    try {
      const response = await axiosInstance.get(`/user-ranking/${userId}`)
      return response.data
    } catch (error) {
      console.error("Erreur lors de la récupération du classement de l'utilisateur:", error)
      return {
        rank: 0,
        finalScore: 0
      }
    }
  },
  getUserBestVideo: async (userId) => {
    try {
      const response = await axiosInstance.get(`/user-best-video/${userId}`);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la récupération de la meilleure vidéo:", error);
      return null;
    }
  },
}


import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { Gamepad, EmojiEvents, Psychology, Whatshot } from '@mui/icons-material';
import arcadeHeader from '../../assets/headerCategoriesPhotos/arcade.jpg';

export function ArcadePage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const arcadeInfoCards = [
    {
      icon: <Gamepad />,
      title: 'Scores Parfaits',
      desc: 'Les performances qui pulvérisent les records'
    },
    {
      icon: <Psychology />,
      title: 'Techniques Pro',
      desc: 'Les mouvements avancés qui font la différence'
    },
    {
      icon: <Whatshot />,
      title: 'Combos Infinis',
      desc: 'Les enchaînements les plus impressionnants'
    },
    {
      icon: <EmojiEvents />,
      title: 'Runs Parfaits',
      desc: 'Les parties sans faute qui marquent l\'histoire'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="ARCADE ARENA"
        description="Plongez dans l'univers électrisant des jeux d'arcade. Des combos parfaits aux high scores légendaires, votez pour les performances qui repoussent les limites du possible."
        color="#00b894"
        backgroundImage={arcadeHeader}
        infoCards={arcadeInfoCards}
      />

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#00b894"
              buttonGradient={{
                from: '#00b894',
                to: '#1a237e'
              }}
              hoverShadowColor="rgba(0, 184, 148, 0.5)"
              vsColor="#00b894"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
} 
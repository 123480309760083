import React from 'react';
import PropTypes from 'prop-types';
import CustomPlayer from '../../Player/Player';
import { Box, Typography, Chip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { useTranslation } from "react-i18next";

export default function SingleHighlight({ video }) {
  const { t } = useTranslation('app');

  return (
    <Box sx={{
      position: 'relative',
      borderRadius: '16px',
      overflow: 'hidden',
      background: 'rgba(0,0,0,0.2)',
      border: '1px solid rgba(173, 83, 137, 0.3)',
      transition: 'all 0.3s ease',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
        border: '1px solid rgba(173, 83, 137, 0.6)',
      }
    }}>
      <Box sx={{ 
        position: 'relative',
        width: '100%',
        aspectRatio: '16/9',
      }}>
        <CustomPlayer
          layoutType="ultraMinimal"
          src={video.url}
        />
      </Box>

      <Box sx={{
        padding: '12px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        <Typography 
          variant="h6" 
          sx={{
            fontSize: '1rem',
            fontWeight: 500,
            mb: 1,
            color: 'white',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {video.title}
        </Typography>

        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 'auto'
        }}>
          <Chip
            icon={<PersonIcon sx={{ fontSize: '0.8rem' }} />}
            label={video.uploader}
            size="small"
            sx={{
              background: 'rgba(173, 83, 137, 0.1)',
              border: '1px solid rgba(173, 83, 137, 0.3)',
              color: 'white',
              '&:hover': {
                background: 'rgba(173, 83, 137, 0.3)',
              },
            }}
          />
          <Chip
            icon={<SportsEsportsIcon sx={{ fontSize: '0.8rem' }} />}
            label={video.game}
            size="small"
            sx={{
              background: 'rgba(173, 83, 137, 0.1)',
              border: '1px solid rgba(173, 83, 137, 0.3)',
              color: 'white',
              '&:hover': {
                background: 'rgba(173, 83, 137, 0.3)',
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

SingleHighlight.propTypes = {
  video: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    uploader: PropTypes.string.isRequired,
    game: PropTypes.string.isRequired,
  }).isRequired,
};

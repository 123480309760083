import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { SportsKabaddi, FlashOn, EmojiEvents, Psychology } from '@mui/icons-material';
import fightGameHeader from '../../assets/headerCategoriesPhotos/fightGame.jpg';

export function CombatPage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const combatInfoCards = [
    {
      icon: <SportsKabaddi />,
      title: 'Combos Dévastateurs',
      desc: 'Les enchaînements les plus techniques et impressionnants'
    },
    {
      icon: <FlashOn />,
      title: 'Réflexes Surhumains',
      desc: 'Des parades et contre-attaques parfaitement exécutées'
    },
    {
      icon: <Psychology />,
      title: 'Mind Games',
      desc: 'Les meilleurs mind games et lectures de l\'adversaire'
    },
    {
      icon: <EmojiEvents />,
      title: 'Comebacks Épiques',
      desc: 'Les retournements de situation les plus spectaculaires'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="FIGHTING ARENA"
        description="Plongez dans l'intensité des jeux de combat où chaque frame compte. Votez pour les combos les plus impressionnants et les lectures d'adversaire les plus incroyables."
        color="#ff6b81"
        backgroundImage={fightGameHeader}
        infoCards={combatInfoCards}
      />

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#ff6b81"
              buttonGradient={{
                from: '#ff6b81',
                to: '#4a1f4a'
              }}
              hoverShadowColor="rgba(255, 107, 129, 0.5)"
              vsColor="#ff6b81"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
} 
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';

const CursorRing = styled('div')({
  width: '20px',
  height: '20px',
  border: '2px solid rgba(255, 255, 255, 0.95)',
  borderRadius: '50%',
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 9999,
  transition: 'width 0.3s, height 0.3s, border-color 0.3s',
  transform: 'translate(-50%, -50%)',
  boxShadow: '0 0 10px rgba(255, 255, 255, 0.3)',
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: -1,
    borderRadius: '50%',
    padding: '1px',
    background: 'linear-gradient(45deg, rgba(255,255,255,0.3), transparent)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: -4,
    border: '2px solid rgba(255, 255, 255, 0.15)',
    borderRadius: '50%',
    animation: 'pulseRing 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite',
    filter: 'blur(0.5px)',
  },
  '@keyframes pulseRing': {
    '0%': {
      transform: 'scale(1)',
      opacity: 0.3,
    },
    '50%': {
      transform: 'scale(1.2)',
      opacity: 0,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0.3,
    },
  },
});

export default function CustomCursor() {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    const updateCursorPosition = (e) => {
      setPosition({ 
        x: e.clientX,
        y: e.clientY
      });
    };

    const updateCursorState = (e) => {
      const isClickable = e.target.closest('button, a, [role="button"]');
      setIsHovering(!!isClickable);
    };

    document.addEventListener('mousemove', updateCursorPosition);
    document.addEventListener('mouseover', updateCursorState);

    return () => {
      document.removeEventListener('mousemove', updateCursorPosition);
      document.removeEventListener('mouseover', updateCursorState);
    };
  }, []);

  return (
    <CursorRing
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        position: 'fixed',
        width: isHovering ? '30px' : '20px',
        height: isHovering ? '30px' : '20px',
        borderColor: isHovering ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.95)',
        boxShadow: isHovering 
          ? '0 0 15px rgba(255, 255, 255, 0.5), inset 0 0 8px rgba(255, 255, 255, 0.3)' 
          : '0 0 10px rgba(255, 255, 255, 0.3)',
      }}
    />
  );
} 
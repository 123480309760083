import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { Gamepad, EmojiEvents, Psychology, Whatshot } from '@mui/icons-material';
import retroHeader from '../../assets/headerCategoriesPhotos/retro.jpg';

export function RetroPage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const retroInfoCards = [
    {
      icon: <Gamepad />,
      title: 'Runs Légendaires',
      desc: 'Les speedruns et performances historiques'
    },
    {
      icon: <Psychology />,
      title: 'Glitches Mythiques',
      desc: 'Les exploits techniques les plus impressionnants'
    },
    {
      icon: <Whatshot />,
      title: 'High Scores',
      desc: 'Les records qui défient le temps'
    },
    {
      icon: <EmojiEvents />,
      title: 'Nostalgie Pure',
      desc: 'Les moments qui ont marqué une génération'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="RETRO ARENA"
        description="Revivez la magie des jeux d'antan à travers leurs moments les plus mémorables. Des speedruns aux high scores, votez pour les exploits qui ont traversé les époques."
        color="#e84393"
        backgroundImage={retroHeader}
        infoCards={retroInfoCards}
      />

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#e84393"
              buttonGradient={{
                from: '#e84393',
                to: '#6b2b42'
              }}
              hoverShadowColor="rgba(232, 67, 147, 0.5)"
              vsColor="#e84393"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
}
// apps/web/src/Pages/ProfiPage/ProfilComposant/RankingStats.jsx

import React, { useEffect, useState } from 'react';
import { Box, Typography, styled, CircularProgress, Alert } from '@mui/material';
import { EmojiEvents, TrendingUp } from '@mui/icons-material';
import { Api } from '../../../services/Api';
import PropTypes from 'prop-types';
import { keyframes } from '@emotion/react';

// Animations raffinées
const shimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

const gradientMove = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const RankingContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '700px',
  margin: '20px auto',
  padding: '25px',
  background: 'rgba(255, 255, 255, 0.03)',
  backdropFilter: 'blur(10px)',
  borderRadius: '20px',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  overflow: 'hidden',
  transition: 'all 0.4s ease',

  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: 'linear-gradient(120deg, transparent 20%, rgba(255,255,255,0.05) 40%, transparent 60%)',
    animation: `${shimmer} 3s infinite linear`,
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: 'radial-gradient(circle at 50% 0%, rgba(255,255,255,0.1), transparent 70%)',
    opacity: 0.5,
  },

  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
    background: 'rgba(255, 255, 255, 0.04)',
  }
}));

const StatsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  gap: '40px',
  position: 'relative',
  zIndex: 1,

  '@media (max-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  }
});

const StatItem = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px 30px',
  background: 'rgba(255, 255, 255, 0.02)',
  borderRadius: '16px',
  transition: 'all 0.3s ease',
  minWidth: '180px',

  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    borderRadius: '16px',
    padding: '1px',
    background: 'linear-gradient(120deg, rgba(255,255,255,0.2), transparent)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },

  '&:hover': {
    transform: 'translateY(-3px)',
    background: 'rgba(255, 255, 255, 0.04)',
  }
});

const StatIcon = styled(Box)(({ color }) => ({
  width: '48px',
  height: '48px',
  borderRadius: '14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(135deg, ${color}20, transparent)`,
  marginBottom: '12px',
  position: 'relative',
  transition: 'all 0.3s ease',

  '& svg': {
    fontSize: '24px',
    color: color,
    filter: `drop-shadow(0 0 6px ${color}40)`,
    transition: 'all 0.3s ease',
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    inset: -1,
    borderRadius: '14px',
    padding: '1px',
    background: `linear-gradient(45deg, ${color}60, transparent)`,
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },

  [`${StatItem}:hover &`]: {
    transform: 'scale(1.1)',
    '& svg': {
      transform: 'scale(1.1)',
      filter: `drop-shadow(0 0 8px ${color}60)`,
    }
  }
}));

const StatLabel = styled(Typography)({
  fontSize: '0.85rem',
  color: 'rgba(255, 255, 255, 0.7)',
  marginBottom: '4px',
  textTransform: 'uppercase',
  letterSpacing: '1.5px',
  fontWeight: 500,
  transition: 'color 0.3s ease',

  [`${StatItem}:hover &`]: {
    color: 'rgba(255, 255, 255, 0.9)',
  }
});

const StatValue = styled(Typography)({
  fontSize: '1.8rem',
  fontWeight: 600,
  background: 'linear-gradient(to right, #fff, rgba(255,255,255,0.8))',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textShadow: '0 0 15px rgba(255,255,255,0.1)',
  transition: 'all 0.3s ease',

  [`${StatItem}:hover &`]: {
    textShadow: '0 0 20px rgba(255,255,255,0.2)',
  }
});

const Title = styled(Typography)({
  fontSize: '1.1rem',
  fontWeight: 500,
  color: 'rgba(255, 255, 255, 0.85)',
  textAlign: 'center',
  marginBottom: '25px',
  position: 'relative',
  paddingBottom: '12px',
  letterSpacing: '0.5px',

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '2px',
    background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.3), transparent)',
  }
});

export default function RankingStats({ userId }) {
  const [rankingData, setRankingData] = useState({
    rank: 0,
    finalScore: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRanking = async () => {
      try {
        const data = await Api.getUserRanking(userId);
        setRankingData({
          rank: data.rank,
          finalScore: data.finalScore,
        });
        setLoading(false);
      } catch (err) {
        console.error('Erreur lors de la récupération du classement:', err);
        setError('Impossible de charger le classement.');
        setLoading(false);
      }
    };

    fetchRanking();
  }, [userId]);

  if (loading) {
    return (
      <RankingContainer>
        <CircularProgress color="secondary" />
      </RankingContainer>
    );
  }

  if (error) {
    return (
      <RankingContainer>
        <Alert severity="error">{error}</Alert>
      </RankingContainer>
    );
  }

  return (
    <RankingContainer>
      <Title variant="h6">Classement Global</Title>
      
      <StatsBox>
        <StatItem>
          <StatIcon color="#FFD700">
            <EmojiEvents fontSize="large" />
          </StatIcon>
          <StatLabel>Rang</StatLabel>
          <StatValue>{`#${rankingData.rank || 0}`}</StatValue>
        </StatItem>
        
        <StatItem>
          <StatIcon color="#4CAF50">
            <TrendingUp fontSize="large" />
          </StatIcon>
          <StatLabel>Score</StatLabel>
          <StatValue>{rankingData.finalScore?.toLocaleString() || '0'}</StatValue>
        </StatItem>
      </StatsBox>
    </RankingContainer>
  );
}

RankingStats.propTypes = {
  userId: PropTypes.string.isRequired,
};
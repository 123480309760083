import React, { useEffect, useState } from 'react';
import { Box, Typography, styled, Tooltip } from '@mui/material';
import { GradeOutlined, InsightsOutlined } from '@mui/icons-material';
import { useKeycloak } from '../../context/keycloakContext';
import { Api } from '../../services/Api';
import { useTranslation } from 'react-i18next';
import { keyframes } from '@mui/system';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const StatsContainer = styled(Box)(({ theme, isOpen }) => ({
  position: 'absolute',
  bottom: isOpen ? 20 : 80,
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  flexDirection: isOpen ? 'row' : 'column',
  gap: '12px',
  width: isOpen ? '85%' : '40px',
  alignItems: 'center',
  justifyContent: 'center',
  animation: `${fadeIn} 0.3s ease-out`,
}));

const StatCard = styled(Box)(({ theme, isOpen }) => ({
  padding: isOpen ? '12px 16px' : '8px',
  borderRadius: '12px',
  background: 'rgba(255, 255, 255, 0.02)',
  backdropFilter: 'blur(8px)',
  display: 'flex',
  alignItems: 'center',
  gap: isOpen ? '12px' : '0',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  width: isOpen ? '50%' : '40px',
  height: isOpen ? '52px' : '40px',
  justifyContent: 'center',
  position: 'relative',
  cursor: 'default',

  '&:hover': {
    background: 'rgba(255, 255, 255, 0.03)',
    transform: 'translateY(-2px)',
    '& .icon-wrapper': {
      background: 'linear-gradient(45deg, rgba(173,83,137,0.15), rgba(60,16,83,0.15))',
    },
    '& .stat-value': {
      color: '#ad5389',
    }
  }
}));

const IconWrapper = styled(Box)(({ theme, isOpen }) => ({
  width: isOpen ? '32px' : '28px',
  height: isOpen ? '32px' : '28px',
  borderRadius: '8px',
  background: 'rgba(173,83,137,0.08)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s ease',
  className: 'icon-wrapper',

  '& svg': {
    fontSize: isOpen ? '18px' : '16px',
    color: 'rgba(173,83,137,0.9)',
    transition: 'all 0.3s ease',
  }
}));

const StatsContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  minWidth: 0,
});

export default function DrawerStats({ isOpen }) {
  const { isAuthenticated, user } = useKeycloak();
  const { t } = useTranslation('app');
  const [ranking, setRanking] = useState(null);
  const [score, setScore] = useState(null);

  useEffect(() => {
    const fetchUserRanking = async () => {
      if (isAuthenticated && user?.sub) {
        const data = await Api.getUserRanking(user.sub);
        setRanking(data.rank);
        setScore(data.finalScore);
      }
    };
    fetchUserRanking();
  }, [isAuthenticated, user?.sub]);

  if (!isAuthenticated || !user?.sub) return null;

  const renderStatCard = (icon, label, value) => (
    <Tooltip title={!isOpen ? `${label}: ${value}` : ''} placement="right">
      <StatCard isOpen={isOpen}>
        <IconWrapper isOpen={isOpen} className="icon-wrapper">
          {icon}
        </IconWrapper>
        {isOpen && (
          <StatsContent>
            <Typography 
              variant="caption"
              sx={{ 
                fontSize: '0.7rem',
                color: 'rgba(255,255,255,0.5)',
                textTransform: 'uppercase',
                letterSpacing: '0.5px',
                fontWeight: 500,
                whiteSpace: 'nowrap',
              }}
            >
              {label}
            </Typography>
            <Typography 
              className="stat-value"
              sx={{ 
                fontSize: '1rem',
                fontWeight: 600,
                color: 'rgba(255,255,255,0.85)',
                lineHeight: 1,
                transition: 'color 0.3s ease',
                whiteSpace: 'nowrap',
              }}
            >
              {value}
            </Typography>
          </StatsContent>
        )}
      </StatCard>
    </Tooltip>
  );

  return (
    <StatsContainer isOpen={isOpen}>
      {renderStatCard(<GradeOutlined />, t('classement'), `#${ranking}`)}
      {renderStatCard(<InsightsOutlined />, t('score'), score?.toLocaleString())}
    </StatsContainer>
  );
} 
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { Casino, Stars, EmojiEvents, Whatshot } from '@mui/icons-material';
import casinoHeader from '../../assets/headerCategoriesPhotos/casinoGame.jpg';

export function CasinoPage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const casinoInfoCards = [
    {
      icon: <Casino />,
      title: 'Coups du Destin',
      desc: 'Les mains les plus improbables et spectaculaires'
    },
    {
      icon: <Stars />,
      title: 'Bluffs Légendaires',
      desc: 'Les plus beaux bluffs et lectures de jeu'
    },
    {
      icon: <Whatshot />,
      title: 'All-In Épiques',
      desc: 'Les moments de tension ultime et décisifs'
    },
    {
      icon: <EmojiEvents />,
      title: 'Jackpots Incroyables',
      desc: 'Les gains les plus impressionnants'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="CASINO ARENA"
        description="Découvrez les moments les plus intenses des jeux de casino. Des bluffs aux jackpots, votez pour les séquences qui vous ont le plus impressionné."
        color="#a4b0be"
        backgroundImage={casinoHeader}
        infoCards={casinoInfoCards}
      />

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#a4b0be"
              buttonGradient={{
                from: '#a4b0be',
                to: '#36454f'
              }}
              hoverShadowColor="rgba(164, 176, 190, 0.5)"
              vsColor="#a4b0be"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
} 
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Typography, styled } from '@mui/material';

const StatsPaper = styled(Paper)(({ theme }) => ({
    height: '100%',
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(145deg, rgba(255,255,255,0.05) 0%, rgba(173,83,137,0.05) 100%)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255,255,255,0.1)',
    borderRadius: '24px',
    position: 'relative',
    overflow: 'hidden',
    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',

    '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        background: 'radial-gradient(circle at 50% 0%, rgba(173,83,137,0.1), transparent 70%)',
        opacity: 0,
        transition: 'opacity 0.4s ease',
    },

    '&::after': {
        content: '""',
        position: 'absolute',
        inset: -1,
        background: 'linear-gradient(145deg, rgba(173,83,137,0.3), transparent)',
        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        maskComposite: 'exclude',
        opacity: 0,
        transition: 'opacity 0.4s ease',
    },

    '&:hover': {
        transform: 'translateY(-5px)',
        background: 'linear-gradient(145deg, rgba(255,255,255,0.07) 0%, rgba(173,83,137,0.07) 100%)',
        boxShadow: '0 15px 30px rgba(173,83,137,0.15)',
        '&::before': {
            opacity: 1,
        },
        '&::after': {
            opacity: 1,
        },
    },
}));

export default function StatsCard({ icon: Icon, label, value }) {
    return (
        <StatsPaper elevation={0}>
            <Box
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        background: 'linear-gradient(45deg, rgba(173,83,137,0.2), rgba(60,16,83,0.2))',
                        borderRadius: '16px',
                        padding: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Icon
                        sx={{
                            fontSize: '32px',
                            color: 'rgba(173,83,137,0.9)',
                            filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
                        }}
                    />
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography 
                        sx={{ 
                            color: 'rgba(255,255,255,0.7)',
                            fontSize: '0.85rem',
                            textTransform: 'uppercase',
                            letterSpacing: '2px',
                            fontFamily: '"Rajdhani", sans-serif',
                            mb: 1,
                        }}
                    >
                        {label}
                    </Typography>
                    <Typography 
                        sx={{ 
                            color: '#ad5389',
                            fontWeight: 700,
                            fontSize: '2.5rem',
                            fontFamily: '"Rajdhani", sans-serif',
                            textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                            lineHeight: 1,
                        }}
                    >
                        {value}
                    </Typography>
                </Box>
            </Box>
        </StatsPaper>
    );
}

StatsCard.propTypes = {
    icon: PropTypes.elementType.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}; 
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton, styled } from '@mui/material';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import CustomPlayer from '../../Player/Player';

// Style du conteneur principal du carousel
const CarouselWrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '70vh',
  backgroundColor: 'transparent',
  overflow: 'hidden',
});

// Style du conteneur de la vidéo
const VideoWrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
});

// Boutons de navigation
const NavButton = styled(IconButton)(({ direction }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  [direction]: 20,
  color: 'white',
  opacity: 0.5,
  transition: 'opacity 0.2s ease, background-color 0.2s ease',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  backdropFilter: 'blur(4px)',
  padding: '8px',
  '&:hover': {
    opacity: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    transform: 'translateY(-50%) scale(1.1)',
  },
}));

// Overlay d'informations avec dégradé
const InfoOverlay = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  background: `
    linear-gradient(
      0deg,
      rgba(0,0,0,0.95) 0%,
      rgba(0,0,0,0.8) 20%,
      rgba(0,0,0,0.6) 40%,
      rgba(0,0,0,0.2) 70%,
      transparent 100%
    )
  `,
  padding: '60px 30px 20px',
  color: 'white',
  zIndex: 2, // Assure que l'overlay reste au-dessus de la vidéo
});

// Tag de jeu
const GameTag = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '4px 12px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '4px',
  backdropFilter: 'blur(8px)',
  marginTop: '8px',
  fontSize: '0.85rem',
  color: 'rgba(255, 255, 255, 0.9)',
  letterSpacing: '0.5px',
  fontWeight: 500,
  border: '1px solid rgba(255, 255, 255, 0.1)',
});

// Barre de progression
const ProgressBar = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '2px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  '& .progress': {
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    transition: 'width 0.3s linear',
  },
});

// Indicateurs de navigation (lignes)
const LinesContainer = styled(Box)({
  position: 'absolute',
  bottom: '10px',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  gap: '6px',
  zIndex: 3,
});

const Line = styled(Box)(({ active }) => ({
  width: '20px',
  height: '3px',
  borderRadius: '2px',
  backgroundColor: active ? '#fff' : 'rgba(255, 255, 255, 0.4)',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  '&:hover': {
    backgroundColor: '#fff',
    transform: 'scaleX(1.1)',
  },
}));

export default function VideoCarousel({ videos, autoPlayInterval = 60000 }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          handleNext();
          return 0;
        }
        return prev + (100 / (autoPlayInterval / 1000));
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [autoPlayInterval, videos.length]);

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % videos.length);
    setProgress(0);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev - 1 + videos.length) % videos.length);
    setProgress(0);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    setProgress(0);
  };

  const currentVideo = videos[currentIndex];

  return (
    <CarouselWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <VideoWrapper>
        <CustomPlayer
          src={currentVideo.videoUrl}
          autoPlay
          muted
          loop
        />
      </VideoWrapper>

      <NavButton
        direction="left"
        onClick={handlePrev}
        sx={{ opacity: isHovered ? 0.7 : 0 }}
      >
        <NavigateBefore sx={{ fontSize: 28 }} />
      </NavButton>

      <NavButton
        direction="right"
        onClick={handleNext}
        sx={{ opacity: isHovered ? 0.7 : 0 }}
      >
        <NavigateNext sx={{ fontSize: 28 }} />
      </NavButton>

      <InfoOverlay>
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 600,
            fontSize: '2rem',
            letterSpacing: '0.2px',
            fontFamily: '"Rajdhani", sans-serif',
            marginBottom: 0.5,
          }}
        >
          {currentVideo.title}
        </Typography>
        
        <GameTag>
          {currentVideo.game}
        </GameTag>

        <Typography 
          variant="body1" 
          sx={{ 
            maxWidth: '500px',
            mt: 1.5,
            opacity: 0.8,
            fontSize: '0.95rem',
            lineHeight: 1.5,
            color: 'rgba(255, 255, 255, 0.9)',
          }}
        >
          {currentVideo.description}
        </Typography>
      </InfoOverlay>

      <ProgressBar>
        <Box 
          className="progress" 
          sx={{ width: `${progress}%` }} 
        />
      </ProgressBar>

      {/* Indicateurs de navigation (lignes) */}
      <LinesContainer>
        {videos.map((_, index) => (
          <Line
            key={index}
            active={index === currentIndex}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </LinesContainer>
    </CarouselWrapper>
  );
}

VideoCarousel.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      videoUrl: PropTypes.string.isRequired,
      game: PropTypes.string.isRequired,
    })
  ).isRequired,
  autoPlayInterval: PropTypes.number,
};


import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { Explore, Psychology, EmojiEvents, Whatshot } from '@mui/icons-material';
import aventureHeader from '../../assets/headerCategoriesPhotos/aventure.jpg';

export function AventurePage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const aventureInfoCards = [
    {
      icon: <Explore />,
      title: 'Découvertes Épiques',
      desc: 'Les trouvailles et secrets les plus incroyables'
    },
    {
      icon: <Psychology />,
      title: 'Résolutions Brillantes',
      desc: 'Les solutions d\'énigmes les plus ingénieuses'
    },
    {
      icon: <Whatshot />,
      title: 'Moments Intenses',
      desc: 'Les séquences d\'action les plus mémorables'
    },
    {
      icon: <EmojiEvents />,
      title: 'Exploits Héroïques',
      desc: 'Les accomplissements qui défient l\'imagination'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="ADVENTURE ARENA"
        description="Embarquez pour des aventures extraordinaires. Des découvertes aux combats épiques, votez pour les moments qui vous ont fait rêver et vibrer d'émotion."
        color="#00cec9"
        backgroundImage={aventureHeader}
        infoCards={aventureInfoCards}
      />

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#00cec9"
              buttonGradient={{
                from: '#00cec9',
                to: '#05464e'
              }}
              hoverShadowColor="rgba(0, 206, 201, 0.5)"
              vsColor="#00cec9"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
}
import { RouterProvider } from 'react-router-dom';
import getRouter from './Router';
import { useKeycloak } from './context/keycloakContext';
import CustomCursor from './components/CustomCursor/CustomCursor';

export default function MyApp() {
  const kc = useKeycloak();
  const router = getRouter(kc.isAuthenticated, kc.user);

  return (
    <>
      <CustomCursor />
      <RouterProvider router={router}/>
    </>
  );
}

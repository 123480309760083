import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { Speed, Timer, EmojiEvents, AutoGraph } from '@mui/icons-material';
import racingHeader from '../../assets/headerCategoriesPhotos/racing.jpg';

export function CoursePage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const courseInfoCards = [
    {
      icon: <Speed />,
      title: 'Dépassements Légendaires',
      desc: 'Votez pour les manœuvres les plus audacieuses et les trajectoires parfaites'
    },
    {
      icon: <Timer />,
      title: 'Records Pulvérisés',
      desc: 'Découvrez les performances qui repoussent les limites du chrono'
    },
    {
      icon: <AutoGraph />,
      title: 'Lignes Parfaites',
      desc: 'Appréciez l\'art de la course avec les meilleures techniques de pilotage'
    },
    {
      icon: <EmojiEvents />,
      title: 'Moments Décisifs',
      desc: 'Les derniers tours qui ont marqué l\'histoire des courses'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="RACING ARENA"
        description="Devenez juge des moments les plus palpitants du sport automobile virtuel. Chaque vote compte pour élire les dépassements les plus spectaculaires et les performances qui resteront dans les annales de la compétition."
        color="#1e90ff"
        backgroundImage={racingHeader}
        infoCards={courseInfoCards}
      />

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#1e90ff"
              buttonGradient={{
                from: '#1e90ff',
                to: '#1a365d'
              }}
              hoverShadowColor="rgba(30, 144, 255, 0.5)"
              vsColor="#1e90ff"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
} 
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { AutoFixHigh, Psychology, EmojiEvents, Whatshot } from '@mui/icons-material';
import rpgHeader from '../../assets/headerCategoriesPhotos/rpg.jpg';

export function RpgPage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const rpgInfoCards = [
    {
      icon: <AutoFixHigh />,
      title: 'Combats Épiques',
      desc: 'Les affrontements les plus spectaculaires contre les boss'
    },
    {
      icon: <Psychology />,
      title: 'Builds Uniques',
      desc: 'Les combinaisons de sorts et d\'équipements les plus créatives'
    },
    {
      icon: <Whatshot />,
      title: 'Moments Héroïques',
      desc: 'Les actions qui ont marqué l\'histoire de vos aventures'
    },
    {
      icon: <EmojiEvents />,
      title: 'Quêtes Légendaires',
      desc: 'Les accomplissements les plus impressionnants'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="RPG ARENA"
        description="Explorez les moments les plus épiques de vos aventures RPG. Des combats de boss légendaires aux quêtes impossibles, votez pour les exploits qui vous ont le plus marqué."
        color="#8e44ad"
        backgroundImage={rpgHeader}
        infoCards={rpgInfoCards}
      />

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#8e44ad"
              buttonGradient={{
                from: '#8e44ad',
                to: '#432c51'
              }}
              hoverShadowColor="rgba(142, 68, 173, 0.5)"
              vsColor="#8e44ad"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
} 
import React, { useEffect, useState } from 'react';
import { useKeycloak } from "../../context/keycloakContext";
import { useTranslation } from "react-i18next";
import { Box, Typography, Avatar, Paper, Divider, Grid2, IconButton, styled, Tooltip, Button } from '@mui/material';
import { TrendingUp, EmojiEvents, VideoLibrary, KeyboardArrowLeft, KeyboardArrowRight, VerifiedUser, LocationOn, Edit } from '@mui/icons-material';
import SingleHighlight from '../../components/Feed/SingleHighlight';
import { Api } from '../../services/Api';
import { Navigate, useParams } from "react-router-dom";
import StatsCard from '../../components/StatsCard/StatsCard';
import JeuxStat from './ProfilComposant/JeuxStat';
import BattlesList from './ProfilComposant/BattlesList';
import RankingStats from './ProfilComposant/RankingStats';
import BestHighlight from './ProfilComposant/BestHighlight';

// Styled components améliorés
const ProfileHeader = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '400px',
  borderRadius: '25px',
  overflow: 'hidden',
  marginBottom: '20px',
  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)',
}));

const CoverImage = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `
      linear-gradient(
        180deg,
        rgba(0,0,0,0.2) 0%,
        rgba(0,0,0,0.4) 50%,
        rgba(17,17,17,0.95) 100%
      )
    `,
    zIndex: 1,
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transform: 'scale(1.1)',
    transition: 'transform 0.5s ease',
    filter: 'brightness(0.85) contrast(1.1)',
  },
});

const ProfileInfo = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 2,
  padding: '40px',
  background: 'linear-gradient(to top, rgba(17,17,17,1) 0%, rgba(17,17,17,0.8) 50%, transparent 100%)',
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
});

const UserHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '40px',
  position: 'relative',
});

const StyledAvatar = styled(Avatar)({
  width: 140,
  height: 140,
  border: '4px solid rgba(255, 255, 255, 0.15)',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    border: '4px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '0 8px 40px rgba(0, 0, 0, 0.5)',
  }
});

const UserStats = styled(Box)({
  display: 'flex',
  gap: '40px',
  alignItems: 'center',
  padding: '20px 30px',
  background: 'linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))',
  borderRadius: '20px',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
  }
});

const UserInfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  flex: 1,
});

const StatsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, minmax(200px, 240px))',
  gap: '15px',
  padding: '15px',
  marginTop: '0px',
  marginRight: '40px',
  marginLeft: '40px',
  width: 'auto',
  maxWidth: '800px',
  justifyContent: 'flex-end',
  alignContent: 'start',
  '@media (max-width: 1400px)': {
    gridTemplateColumns: 'repeat(2, minmax(200px, 240px))',
  },
  '@media (max-width: 1100px)': {
    gridTemplateColumns: 'minmax(200px, 240px)',
  },
}));

const StatCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '15px',
  background: 'rgba(255, 255, 255, 0.03)',
  borderRadius: '20px',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  overflow: 'hidden',
  width: '100%',
  minHeight: '90px',

  '&:hover': {
    transform: 'translateY(-5px)',
    background: 'rgba(255, 255, 255, 0.05)',
    borderColor: 'rgba(173, 83, 137, 0.3)',
    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',

    '& .stat-icon': {
      transform: 'scale(1.1)',
      color: '#ad5389',
    },

    '& .stat-value': {
      background: 'linear-gradient(45deg, #ad5389, #3c1053)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },

    '&::after': {
      opacity: 1,
    }
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: 'radial-gradient(circle at top right, rgba(173, 83, 137, 0.1), transparent 70%)',
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
}));

const IconWrapper = styled(Box)({
  minWidth: '45px',
  width: '45px',
  height: '45px',
  borderRadius: '15px',
  background: 'rgba(255, 255, 255, 0.05)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s ease',

  '& svg': {
    fontSize: '30px',
    color: 'white',
    transition: 'all 0.3s ease',
  }
});

const StatContent = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  overflow: 'hidden',
});

const GameTitle = styled(Typography)({
  color: 'white',
  fontSize: '1.3rem',
  fontWeight: 'bold',
  textAlign: 'left',
  padding: '1px 0',
  marginBottom: '5px',
  borderBottom: '2px solid rgba(255, 255, 255, 0.1)',
  paddingRight: '15px',
});

const TrophyGrid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'repeat(2, 90px)',
  columnGap: '15px',
  gap: '5px',
  rowGap: '30px',
  padding: '5px 0 15px 0',
  position: 'relative',
  transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
  marginBottom: '10px',
  '@media (max-width: 600px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(3, 80px)',
    gap: '10px',
    rowGap: '20px',
  },
  '@media (max-width: 400px)': {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridTemplateRows: 'repeat(6, 70px)',
  },
});

const TrophyItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
  padding: '8px',
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '12px',
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  height: '100%',
  maxHeight: '100px',
  '@media (max-width: 768px)': {
    padding: '6px',
    '& > .MuiTypography-root': {
      '&:nth-of-type(1)': {
        fontSize: '1.3rem',
      },
      '&:nth-of-type(2)': {
        fontSize: '0.75rem',
      },
      '&:nth-of-type(3)': {
        fontSize: '0.65rem',
      }
    },
  },
  '& > .MuiTypography-root': {
    '&:nth-of-type(1)': {
      fontSize: '1.5rem',
      marginBottom: '2px',
    },
    '&:nth-of-type(2)': {
      fontSize: '0.8rem',
      lineHeight: '1.2',
    },
    '&:nth-of-type(3)': {
      fontSize: '0.7rem',
      lineHeight: '1.1',
    }
  },
  '&:hover': {
    transform: 'translateY(-3px)',
    background: 'rgba(255, 255, 255, 0.08)',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
  },
});

const TrophyContainer = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
});

const CarouselButton = styled(IconButton)({
  position: 'absolute',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  padding: '8px',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 2,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  '&.left': {
    left: '5px',
  },
  '&.right': {
    right: '5px',
  },
  '@media (max-width: 768px)': {
    padding: '6px',
    '& svg': {
      fontSize: '1.2rem',
    },
  },
});

const useParallax = () => {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const coverImage = document.querySelector('.cover-image');
      if (!coverImage) return;

      const { left, top, width, height } = coverImage.getBoundingClientRect();
      const x = (e.clientX - left) / width - 0.5;
      const y = (e.clientY - top) / height - 0.5;

      coverImage.style.transform = `
        perspective(1000px)
        rotateX(${y * 5}deg)
        rotateY(${x * 5}deg)
        translateZ(0)
        scale(1.1)
      `;
    };

    const handleMouseLeave = () => {
      const coverImage = document.querySelector('.cover-image');
      if (!coverImage) return;
      
      coverImage.style.transform = 'perspective(1000px) rotateX(0) rotateY(0) translateZ(0) scale(1)';
    };

    const header = document.querySelector('.profile-header');
    if (header) {
      header.addEventListener('mousemove', handleMouseMove);
      header.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (header) {
        header.removeEventListener('mousemove', handleMouseMove);
        header.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);
};

export function ProfilPage() {
    const { username } = useParams();
    const { isAuthenticated, user } = useKeycloak();
    const { t } = useTranslation('app');

    // states for user stats and highlights
    const [userProfile, setUserProfile] = useState({});
    const [userHighlights, setUserHighlights] = useState([]);
    const [errorLoadingProfile, setErrorLoadingProfile] = useState(false);
    const [userBattles, setUserBattles] = useState([]);
    const [bestVideo, setBestVideo] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            await Api.getUserProfile(username)
                .then((data) => {
                    if (data === null) {
                        setErrorLoadingProfile(true)
                    }
                    setUserProfile(data)
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération des statistiques de l\'utilisateur', error)
                    setErrorLoadingProfile(true)
                });
        }
        fetchProfile();
    }, [username]);

    useEffect(() => {
        const fetchHighlights = async () => {
            await Api.getUserHighlights(userProfile?.id)
                .then((data) => {
                    setUserHighlights(data)
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération des highlights de l\'utilisateur', error)
                });
        }
        fetchHighlights();
    }, [userProfile])

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (userProfile?.id) {
                    console.log("Fetching battles for user:", userProfile.id);
                    const battles = await Api.getUserBattles(userProfile.id);
                    console.log("Received battles:", battles);
                    setUserBattles(battles);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des battles:', error);
            }
        };

        fetchUserData();
    }, [userProfile?.id]);

    useEffect(() => {
        const fetchBestVideo = async () => {
            if (userProfile?.id) {
                const video = await Api.getUserBestVideo(userProfile.id);
                setBestVideo(video);
            }
        };
        fetchBestVideo();
    }, [userProfile?.id]);

    useParallax();

    if (errorLoadingProfile === true) {
        return (
            <Navigate to="/404" replace={true} />
        )
    }
    return (
        <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                borderRadius: '20px',
                boxShadow: '0 20px 60px rgba(0, 0, 0, 0.3)',
                fontFamily: 'Exo, sans-serif',
                pb: 4,
                overflow: 'hidden',
            }}
        >
            <ProfileHeader className="profile-header">
                <CoverImage>
                    <img 
                        className="cover-image"
                        src="https://picsum.photos/1920/1080" 
                        alt="cover"
                        style={{
                            filter: 'brightness(0.8) saturate(1.2)',
                        }}
                    />
                </CoverImage>
                <ProfileInfo>
                    <UserHeader>
                        <StyledAvatar
                            src={`https://i.pravatar.cc/300?u=${user?.sub || 'guest'}`}
                        />
                        <Box sx={{ flex: 1 }}>
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 2,
                                mb: 2
                            }}>
                                <Typography 
                                    variant="h3" 
                                    sx={{ 
                                        color: 'white',
                                        fontWeight: 700,
                                        letterSpacing: '0.5px',
                                        textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                                        fontSize: '2.5rem',
                                    }}
                                >
                                    {userProfile?.username || 'Username'}
                                </Typography>
                                {userProfile?.verified && (
                                    <Tooltip title="Compte vérifié">
                                        <VerifiedUser 
                                            sx={{ 
                                                fontSize: '2rem',
                                                color: '#2196f3',
                                                filter: 'drop-shadow(0 2px 4px rgba(33,150,243,0.3))'
                                            }} 
                                        />
                                    </Tooltip>
                                )}
                            </Box>
                            <Typography 
                                sx={{ 
                                    color: 'rgba(255,255,255,0.85)',
                                    fontSize: '1.1rem',
                                    mb: 2,
                                    fontStyle: 'italic'
                                }}
                            >
                                " Lighter de talent, be proud "
                            </Typography>
                            <Typography 
                                sx={{ 
                                    color: 'rgba(255,255,255,0.7)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    fontSize: '1rem'
                                }}
                            >
                                <LocationOn sx={{ fontSize: '1.2rem' }} />
                                {userProfile?.location || 'Location'}
                            </Typography>
                        </Box>
                        {isAuthenticated && user?.sub === userProfile?.id && (
                            <Button
                                variant="outlined"
                                startIcon={<Edit />}
                                sx={{
                                    color: 'white',
                                    borderColor: 'rgba(255,255,255,0.3)',
                                    padding: '12px 24px',
                                    borderRadius: '12px',
                                    textTransform: 'none',
                                    fontSize: '1rem',
                                    '&:hover': {
                                        borderColor: 'white',
                                        background: 'rgba(255,255,255,0.1)',
                                        transform: 'translateY(-2px)'
                                    },
                                    transition: 'all 0.3s ease'
                                }}
                            >
                                Éditer le profil
                            </Button>
                        )}
                    </UserHeader>
                    <UserStats>
                        {['Followers', 'Following', 'Highlights'].map((stat, index) => (
                            <Box 
                                key={stat}
                                sx={{ 
                                    textAlign: 'center',
                                    position: 'relative',
                                    '&:not(:last-child)::after': {
                                        content: '""',
                                        position: 'absolute',
                                        right: -20,
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        height: '30px',
                                        width: '1px',
                                        background: 'rgba(255,255,255,0.2)'
                                    }
                                }}
                            >
                                <Typography 
                                    variant="h4" 
                                    sx={{ 
                                        color: 'white', 
                                        fontWeight: 'bold',
                                        mb: 0.5,
                                        fontSize: '2rem'
                                    }}
                                >
                                    {userProfile?.[stat.toLowerCase()] || 0}
                                </Typography>
                                <Typography 
                                    sx={{ 
                                        color: 'rgba(255,255,255,0.7)', 
                                        fontSize: '0.9rem',
                                        letterSpacing: '0.5px'
                                    }}
                                >
                                    {stat}
                                </Typography>
                            </Box>
                        ))}
                    </UserStats>
                </ProfileInfo>
            </ProfileHeader>

            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'flex-start',
              mb: 4,
              gap: '20px',
              flexWrap: 'wrap',
              width: '100%',
              marginTop: 3
            }}>
              <JeuxStat />
              <Box sx={{ 
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                minWidth: '300px',
                mr: 3
              }}>
                <StatsContainer>
                  <StatCard>
                    <IconWrapper className="stat-icon">
                      <TrendingUp />
                    </IconWrapper>
                    <StatContent>
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'rgba(255, 255, 255, 0.7)',
                          fontSize: '0.9rem',
                          textTransform: 'uppercase',
                          letterSpacing: '1px',
                        }}
                      >
                        {t('Batailles gagnées')}
                      </Typography>
                      <Typography
                        className="stat-value"
                        variant="h4"
                        sx={{
                          color: 'white',
                          fontWeight: 'bold',
                          transition: 'all 0.3s ease',
                        }}
                      >
                        {(userProfile && userProfile.wonBattles) ?? 0}
                      </Typography>
                    </StatContent>
                  </StatCard>

                  <StatCard>
                    <IconWrapper className="stat-icon">
                      <EmojiEvents />
                    </IconWrapper>
                    <StatContent>
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'rgba(255, 255, 255, 0.7)',
                          fontSize: '0.9rem',
                          textTransform: 'uppercase',
                          letterSpacing: '1px',
                        }}
                      >
                        {t('Trophées')}
                      </Typography>
                      <Typography
                        className="stat-value"
                        variant="h4"
                        sx={{
                          color: 'white',
                          fontWeight: 'bold',
                          transition: 'all 0.3s ease',
                        }}
                      >
                        {(userProfile && userProfile.trophies) ?? 0}
                      </Typography>
                    </StatContent>
                  </StatCard>

                  <StatCard>
                    <IconWrapper className="stat-icon">
                      <VideoLibrary />
                    </IconWrapper>
                    <StatContent>
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'rgba(255, 255, 255, 0.7)',
                          fontSize: '0.9rem',
                          textTransform: 'uppercase',
                          letterSpacing: '1px',
                        }}
                      >
                        {t('Highlights')}
                      </Typography>
                      <Typography
                        className="stat-value"
                        variant="h4"
                        sx={{
                          color: 'white',
                          fontWeight: 'bold',
                          transition: 'all 0.3s ease',
                        }}
                      >
                        {(userProfile && userProfile.highlights) ?? 0}
                      </Typography>
                    </StatContent>
                  </StatCard>
                  <Box sx={{ 
                  width: '100%',
                    display: 'flex  ',
                    marginRight : 3,
                  mt: 3
                }}>
                  {bestVideo && <BestHighlight video={bestVideo} />}
                </Box>
                </StatsContainer>
              </Box>
            </Box> 
            <Divider sx={{ m: 7, background: '#800080' }} />

            <Box sx={{ 
              display: 'flex',
              gap: '20px',
              padding: '30px',
              width: '100%',
              alignItems: 'flex-start'
            }}>
              <Box sx={{ flex: 1 }}>
                <BattlesList battles={userBattles} />
              </Box>

              <RankingStats userId={userProfile?.id} />
            </Box>

            <Divider sx={{ m: 4, background: '#800080' }} />

            <Box sx={{ 
                width: '100%', 
                display: 'flex',
                flexDirection: 'column',
                gap: '20px'
            }}>
              

                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gap: '20px',
                    p: 3,
                    width: '100%',
                    maxHeight: '800px',
                    overflowY: 'auto',
                    padding: '10px',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'rgba(255, 255, 255, 0.05)',
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: '10px',
                        '&:hover': {
                            background: 'rgba(255, 255, 255, 0.2)',
                        },
                    },
                    '@media (max-width: 1600px)': {
                        gridTemplateColumns: 'repeat(4, 1fr)',
                    },
                    '@media (max-width: 1200px)': {
                        gridTemplateColumns: 'repeat(3, 1fr)',
                    },
                    '@media (max-width: 900px)': {
                        gridTemplateColumns: 'repeat(2, 1fr)',
                    },
                    '@media (max-width: 600px)': {
                        gridTemplateColumns: 'repeat(1, 1fr)',
                    },
                }}>
                    {userHighlights.length > 0 && userHighlights.map((highlight, index) => (
                        <SingleHighlight 
                            key={`my-highlight-${highlight.title}-${index}`} 
                            video={highlight} 
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper } from '@mui/material';
import CustomPlayer from '../../../Player/Player';
import { EmojiEvents } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  background: 'rgba(255, 255, 255, 0.03)',
  backdropFilter: 'blur(10px)',
  borderRadius: '20px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  padding: '15px',
  marginBottom: '20px',
  overflow: 'hidden',
  width: '100%',
  maxWidth: '600px',
  minWidth: '300px',
  transition: 'all 0.3s ease',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '2px',
    background: 'linear-gradient(90deg, #ad5389, #3c1053)',
  },

  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 10px 30px rgba(173, 83, 137, 0.2)',
  }
}));

const VideoContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',
  borderRadius: '8px',
  overflow: 'hidden',
  marginBottom: '8px',
  '& > div': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  '& video': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  }
});

const BestHighlight = ({ video }) => {
  if (!video) return null;

  return (
    <StyledPaper>
      <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
        <EmojiEvents sx={{ color: '#FFD700', fontSize: '1.5rem' }} />
        <Typography variant="h6" sx={{ 
          color: 'white',
          fontWeight: 'bold',
          fontSize: '1rem',
          textShadow: '0 2px 4px rgba(0,0,0,0.3)'
        }}>
          Meilleur Highlight
        </Typography>
      </Box>

      <VideoContainer>
        <CustomPlayer
          src={video.url}
          layoutType="default"
        />
      </VideoContainer>

      <Box sx={{ px: 1 }}>
        <Typography sx={{ 
          color: 'white', 
          mb: 0.5,
          fontSize: '0.9rem',
          fontWeight: 'medium'
        }}>
          {video.title}
        </Typography>
        <Box sx={{ 
          display: 'flex',
          gap: 2,
          color: 'rgba(255,255,255,0.7)',
          fontSize: '0.8rem'
        }}>
          <Typography variant="body2">
            {video.votes} votes
          </Typography>
          <Typography variant="body2">
            {video.winRate}% win rate
          </Typography>
        </Box>
      </Box>
    </StyledPaper>
  );
};

BestHighlight.propTypes = {
  video: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    votes: PropTypes.number.isRequired,
    winRate: PropTypes.number.isRequired
  })
};

export default BestHighlight; 
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { FlashOn, Psychology, EmojiEvents, Groups } from '@mui/icons-material';
import mobaHeader from '../../assets/headerCategoriesPhotos/mobaHeader.jpg';

export function MobaPage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const mobaInfoCards = [
    {
      icon: <FlashOn />,
      title: 'Plays Stratégiques',
      desc: 'Les meilleures initiatives et rotations'
    },
    {
      icon: <Psychology />,
      title: 'Intelligence Tactique',
      desc: 'Des décisions qui changent le cours du match'
    },
    {
      icon: <EmojiEvents />,
      title: 'Teamfights Épiques',
      desc: 'Les combats d\'équipe les plus intenses'
    },
    {
      icon: <Groups />,
      title: 'Synergies Parfaites',
      desc: 'Coordination et combos légendaires'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="MOBA ARENA"
        description="Plongez dans l'univers stratégique des MOBA où chaque décision compte. Découvrez les plays les plus impressionnants, des pentakills aux retournements de situation épiques."
        color="#2ed573"
        backgroundImage={mobaHeader}
        infoCards={mobaInfoCards}
      />

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#2ed573"
              buttonGradient={{
                from: '#2ed573',
                to: '#2c6e4a'
              }}
              hoverShadowColor="rgba(46, 213, 115, 0.5)"
              vsColor="#2ed573"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
} 
import { Box, Tabs, Tab, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';
import {
  SportsEsportsOutlined,
  GpsFixed,
  SportsCricket,
  SportsMotorsports,
  Casino,
  SportsScore,
  SportsEsports,
  Sports,
  Extension,
  Psychology,
  Public,
  ChevronLeft,
  ChevronRight,
  Gamepad,
  PhoneAndroid,
  Explore,
  AutoStories,
  Science
} from '@mui/icons-material';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';

const ScrollContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '700px',
  overflow: 'hidden',
  margin: '0 auto',
}));

const ScrollButton = styled(IconButton)(({ direction }) => ({
  position: 'absolute',
  [direction]: 0,
  zIndex: 2,
  color: 'rgba(255, 255, 255, 0.6)',
  background: 'linear-gradient(90deg, rgba(173,83,137,0.1), transparent)',
  backdropFilter: 'blur(4px)',
  height: '100%',
  width: '30px',
  padding: 0,
  borderRadius: direction === 'left' ? '8px 0 0 8px' : '0 8px 8px 0',
  transition: 'all 0.3s ease',
  opacity: 0.4,
  '&:hover': {
    background: 'linear-gradient(90deg, rgba(173,83,137,0.2), transparent)',
    opacity: 1,
    width: '35px',
    color: 'rgba(255, 255, 255, 0.9)',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1rem',
    transition: 'transform 0.3s ease',
  },
  '&:hover .MuiSvgIcon-root': {
    transform: 'scale(1.2)',
  }
}));

const DEFAULT_THEME = {
  primary: '#ad5389',
  secondary: '#3c1053',
  background: '#19153b'
};

export default function NavBarCat() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const { updateTheme } = useTheme();

  const handleScroll = (direction) => {
    const tabsNode = document.querySelector('.MuiTabs-scroller');
    if (tabsNode) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      tabsNode.scrollLeft += scrollAmount;
    }
  };

  const categories = [
    { label: 'FPS', icon: <GpsFixed />, color: '#ff4757', path: '/categories/fps' },
    { label: 'MOBA', icon: <SportsCricket />, color: '#2ed573', path: '/categories/moba' },
    { label: 'Battle Royale', icon: <SportsScore />, color: '#ffa502', path: '/categories/battle-royale' },
    { label: 'Course', icon: <SportsMotorsports />, color: '#1e90ff', path: '/categories/course' },
    { label: 'Jeux de combat', icon: <SportsEsportsOutlined />, color: '#ff6b81', path: '/categories/combat' },
    { label: 'Jeux de casino', icon: <Casino />, color: '#a4b0be', path: '/categories/casino' },
    { label: 'RPG', icon: <Psychology />, color: '#8e44ad', path: '/categories/rpg' },
    { label: 'MMO', icon: <Public />, color: '#4B6B8C', path: '/categories/mmo' },
    { label: 'Stratégie', icon: <Extension />, color: '#e67e22', path: '/categories/strategie' },
    { label: 'Sports', icon: <Sports />, color: '#5B8A72', path: '/categories/sports' },
    { label: 'Retro', icon: <SportsEsports />, color: '#e84393', path: '/categories/retro' },
    { label: 'Arcade', icon: <Gamepad />, color: '#00b894', path: '/categories/arcade' },
    { label: 'Mobile', icon: <PhoneAndroid />, color: '#6c5ce7', path: '/categories/mobile' },
    { label: 'Aventure', icon: <Explore />, color: '#00cec9', path: '/categories/aventure' },
    { label: 'Simulation', icon: <Science />, color: '#55efc4', path: '/categories/simulation' }
  ];

  const handleCategoryClick = (index, path) => {
    setSelectedCategory(index);
    
    if (path === '/') {
      updateTheme(DEFAULT_THEME.primary);
    } else {
      updateTheme(categories[index].color);
    }
    navigate(path);
  };

  return (
    <ScrollContainer>
      <ScrollButton
        direction="left"
        onClick={() => handleScroll('left')}
      >
        <ChevronLeft />
      </ScrollButton>

      <Tabs
        value={selectedCategory}
        onChange={(_, newValue) => setSelectedCategory(newValue)}
        variant="scrollable"
        scrollButtons={false}
        TabIndicatorProps={{
          style: {
            backgroundColor: categories[selectedCategory]?.color || '#ad5389',
            height: 3,
            borderRadius: '3px',
            boxShadow: `0 0 10px ${categories[selectedCategory]?.color || '#ad5389'}80`
          }
        }}
        sx={{
          width: '100%',
          minHeight: 36,
          '& .MuiTabs-scroller': {
            overflow: 'hidden !important',
            scrollBehavior: 'smooth',
          },
          '& .MuiTabs-flexContainer': {
            gap: 1,
          },
        }}
      >
        {categories.map((category, index) => (
          <Tab
            key={category.label}
            icon={category.icon}
            label={category.label}
            onClick={() => handleCategoryClick(index, category.path)}
            sx={{
              minHeight: 36,
              fontSize: '0.75rem',
              minWidth: '100px',
              maxWidth: '100px',
              color: 'white',
              opacity: 0.7,
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              '&:hover': {
                opacity: 1,
                transform: 'translateY(-2px) scale(1.05)',
                color: category.color,
                background: 'rgba(255,255,255,0.05)',
                boxShadow: `0 4px 15px ${category.color}30`,
              },
              '&.Mui-selected': {
                color: category.color,
                opacity: 1,
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  width: '5px',
                  height: '5px',
                  background: category.color,
                  borderRadius: '50%',
                  transform: 'translateX(-50%)',
                  boxShadow: `0 0 10px ${category.color}`,
                }
              },
              '& .MuiSvgIcon-root': {
                fontSize: '1.1rem',
                marginBottom: '2px',
                transition: 'all 0.3s ease',
              },
              '&:hover .MuiSvgIcon-root': {
                transform: 'scale(1.2) rotate(5deg)',
                filter: `drop-shadow(0 0 3px ${category.color}80)`,
              },
            }}
          />
        ))}
      </Tabs>

      <ScrollButton
        direction="right"
        onClick={() => handleScroll('right')}
      >
        <ChevronRight />
      </ScrollButton>
    </ScrollContainer>
  );
} 
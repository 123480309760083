import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper, Grid } from '@mui/material';

export default function HeaderCategorie({ 
  title,
  description,
  color,
  backgroundImage,
  infoCards
}) {
  const backgroundImageUrl = typeof backgroundImage === 'string' 
    ? backgroundImage 
    : `url(${backgroundImage})`;

  return (
    <Box sx={{
      width: '100%',
      mb: 6,
      position: 'relative',
      overflow: 'hidden',
      borderBottom: `1px solid ${color}33`,
      borderRadius: '0 0 20px 20px',
      paddingBottom: '40px',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 20,
        left: { xs: '10%', md: '25%' },
        width: { xs: '80%', md: '50%' },
        height: '2px',
        background: `linear-gradient(90deg, transparent, ${color}, transparent)`,
        animation: 'glow 3s infinite',
      },
      '@keyframes glow': {
        '0%, 100%': {
          opacity: 0.3,
          filter: 'blur(2px)',
        },
        '50%': {
          opacity: 1,
          filter: 'blur(0px)',
        },
      }
    }}>
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: { xs: '300px', sm: '350px', md: '400px' },
        background: `url(${backgroundImageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        filter: 'brightness(0.3)',
        transform: 'scale(1.1)',
        transition: 'transform 0.3s ease-out',
        animation: 'subtleZoom 20s infinite alternate',
        borderRadius: '0 0 20px 20px',
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '70%',
          background: 'linear-gradient(to top, rgba(26, 26, 46, 1), transparent)',
          borderRadius: '0 0 20px 20px',
        },
        '@keyframes subtleZoom': {
          '0%': { transform: 'scale(1)' },
          '100%': { transform: 'scale(1.1)' },
        }
      }} />

      <Box sx={{
        position: 'relative',
        pt: { xs: 4, sm: 6, md: 8 },
        pb: { xs: 4, sm: 5, md: 6 },
        px: { xs: 2, sm: 3, md: 4 },
        color: 'white',
        zIndex: 2,
      }}>
        <Typography 
          variant="h1" 
          sx={{
            fontSize: { 
              xs: '2rem',
              sm: '3rem', 
              md: '4.5rem' 
            },
            fontWeight: '800',
            fontFamily: '"Orbitron", sans-serif',
            letterSpacing: { xs: '2px', sm: '3px', md: '4px' },
            textTransform: 'uppercase',
            textShadow: `
              0 0 10px ${color}33,
              0 0 20px ${color}22,
              0 0 30px ${color}11
            `,
            mb: { xs: 1, sm: 1.5, md: 2 },
            background: `linear-gradient(45deg, ${color}, ${color}dd)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: -10,
              left: 0,
              width: { xs: '40px', sm: '50px', md: '60px' },
              height: { xs: '3px', md: '4px' },
              background: color,
              animation: 'expandLine 3s infinite',
            },
            '@keyframes expandLine': {
              '0%, 100%': { width: '60px', opacity: 0.5 },
              '50%': { width: '120px', opacity: 1 },
            }
          }}
        >
          {title}
        </Typography>

        <Typography 
          variant="h5" 
          sx={{ 
            maxWidth: '800px',
            mb: { xs: 3, sm: 4, md: 6 },
            color: 'rgba(255,255,255,0.9)',
            textShadow: '0 2px 4px rgba(0,0,0,0.5)',
            fontSize: { 
              xs: '0.9rem', 
              sm: '1rem', 
              md: '1.1rem' 
            },
            lineHeight: 1.6,
            opacity: 0,
            animation: 'fadeInUp 0.8s ease-out forwards',
            animationDelay: '0.3s',
            '@keyframes fadeInUp': {
              from: {
                opacity: 0,
                transform: 'translateY(20px)'
              },
              to: {
                opacity: 1,
                transform: 'translateY(0)'
              }
            }
          }}
        >
          {description}
        </Typography>

        <Grid container spacing={{ xs: 2, sm: 2, md: 3 }} sx={{ 
          mt: { xs: 1, sm: 1.5, md: 2 }
        }}>
          {infoCards.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper sx={{
                p: 3,
                height: '100%',
                background: 'rgba(255,255,255,0.05)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '16px',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  background: 'rgba(255,255,255,0.08)',
                  boxShadow: `0 8px 25px ${color}26`,
                }
              }}>
                <Box sx={{ mb: 2 }}>
                  {React.cloneElement(item.icon, { 
                    sx: { fontSize: '2.5rem', color: color } 
                  })}
                </Box>
                <Typography variant="h6" sx={{ mb: 1, color: 'white' }}>
                  {item.title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                  {item.desc}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

HeaderCategorie.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  backgroundImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  infoCards: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
  })).isRequired,
}; 
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { Psychology, Timeline, EmojiEvents, AutoGraph } from '@mui/icons-material';
import strategieHeader from '../../assets/headerCategoriesPhotos/strategie2.jpg';

export function StrategiePage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const strategieInfoCards = [
    {
      icon: <Psychology />,
      title: 'Coups de Maître',
      desc: 'Les stratégies les plus brillantes et inattendues'
    },
    {
      icon: <Timeline />,
      title: 'Timing Parfait',
      desc: 'Les décisions cruciales au moment opportun'
    },
    {
      icon: <AutoGraph />,
      title: 'Macro Géniale',
      desc: 'La gestion des ressources la plus optimisée'
    },
    {
      icon: <EmojiEvents />,
      title: 'Victoires Écrasantes',
      desc: 'Les dominations stratégiques totales'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="STRATEGIE ARENA"
        description="Découvrez les manœuvres stratégiques les plus brillantes. Des retournements de situation aux victoires écrasantes, votez pour les décisions qui ont changé le cours de la partie."
        color="#e67e22"
        backgroundImage={strategieHeader}
        infoCards={strategieInfoCards}
      />

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#e67e22"
              buttonGradient={{
                from: '#e67e22',
                to: '#5c4033'
              }}
              hoverShadowColor="rgba(230, 126, 34, 0.5)"
              vsColor="#e67e22"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
}
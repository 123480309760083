import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { PrecisionManufacturing, Psychology, EmojiEvents, Architecture } from '@mui/icons-material';
import simulationHeader from '../../assets/headerCategoriesPhotos/simulation.jpg';

export function SimulationPage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const simulationInfoCards = [
    {
      icon: <PrecisionManufacturing />,
      title: 'Créations Uniques',
      desc: 'Les constructions et designs les plus créatifs'
    },
    {
      icon: <Psychology />,
      title: 'Solutions Ingénieuses',
      desc: 'Les résolutions de problèmes les plus astucieuses'
    },
    {
      icon: <Architecture />,
      title: 'Méga Structures',
      desc: 'Les projets les plus ambitieux et impressionnants'
    },
    {
      icon: <EmojiEvents />,
      title: 'Défis Relevés',
      desc: 'Les accomplissements les plus remarquables'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="SIMULATION ARENA"
        description="Explorez les réalisations les plus impressionnantes des jeux de simulation. Des méga-constructions aux solutions innovantes, votez pour les créations qui repoussent les limites."
        color="#55efc4"
        backgroundImage={simulationHeader}
        infoCards={simulationInfoCards}
      />

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#55efc4"
              buttonGradient={{
                from: '#55efc4',
                to: '#2d5a4c'
              }}
              hoverShadowColor="rgba(85, 239, 196, 0.5)"
              vsColor="#55efc4"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
}
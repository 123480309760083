import { useState, useEffect } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import {
  Menu as MenuIcon,
  FaceOutlined,
  GradeOutlined,
  HomeOutlined,
  InsightsOutlined,
  Person4Outlined, 
  QuestionAnswerOutlined,
  SportsEsportsOutlined,
  VideoLibraryOutlined,
  FlashOn,
  ChevronLeft
} from '@mui/icons-material';
import { useKeycloak } from '../../context/keycloakContext';
import { useTranslation } from 'react-i18next';
import { useTheme, DEFAULT_THEME } from '../../context/ThemeContext';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import DrawerStats from '../DrawerStats/DrawerStats';

// Styles personnalisés améliorés
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  justifyContent: 'space-between',
  position: 'relative',
  marginBottom: '20px',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '10%',
    width: '80%',
    height: '2px',
    background: 'linear-gradient(90deg, transparent, rgba(173, 83, 137, 0.3), transparent)',
  }
}));

const DRAWER_WIDTH = 280;
const MINI_DRAWER_WIDTH = 70;

// Style pour les boutons complets (drawer ouvert)
const FullWidthListItem = styled(ListItem)(({ active }) => ({
  margin: '8px 16px',
  padding: '12px 18px',
  position: 'relative',
  cursor: 'pointer',
  background: 'transparent',
  borderRadius: '14px',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  overflow: 'hidden',
  border: '1px solid transparent',

  // Effet de fond animé pour l'état actif
  ...(active && {
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      background: 'linear-gradient(120deg, rgba(173,83,137,0.1), rgba(60,16,83,0.2))',
      opacity: 1,
      zIndex: -1,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      inset: 0,
      background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.1), transparent)',
      transform: 'translateX(-100%)',
      animation: 'shine 3s infinite',
      zIndex: -1,
    },
  }),

  // Animation de brillance pour l'état actif
  '@keyframes shine': {
    '0%': {
      transform: 'translateX(-100%)',
    },
    '50%, 100%': {
      transform: 'translateX(100%)',
    }
  },

  // Effet de survol
  '&:hover': {
    background: 'rgba(255,255,255,0.03)',
    border: '1px solid rgba(173,83,137,0.3)',
    transform: 'scale(1.02)',
    boxShadow: '0 5px 15px rgba(0,0,0,0.2)',

    '& .MuiListItemIcon-root': {
      transform: 'rotate(10deg) scale(1.2)',
      color: '#ad5389',
    },

    '& .MuiListItemText-primary': {
      letterSpacing: '1px',
      color: '#fff',
    },

    // Effet de particules au survol
    '& .particle': {
      opacity: 1,
      transform: 'translate(0, 0)',
    }
  },

  // Style de l'icône
  '& .MuiListItemIcon-root': {
    minWidth: '42px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: active ? '#ad5389' : 'rgba(255,255,255,0.7)',
    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
    position: 'relative',
    '& svg': {
      fontSize: '1.3rem',
      filter: active ? 'drop-shadow(0 0 8px rgba(173,83,137,0.5))' : 'none',
    }
  },

  // Style du texte
  '& .MuiListItemText-primary': {
    fontSize: '0.95rem',
    fontWeight: active ? 600 : 400,
    color: active ? '#fff' : 'rgba(255,255,255,0.7)',
    letterSpacing: active ? '0.5px' : '0.3px',
    transition: 'all 0.3s ease',
    marginLeft: '8px',
    position: 'relative',
    
    ...(active && {
      textShadow: '0 0 10px rgba(173,83,137,0.3)',
    })
  },

  // Particules décoratives (visibles au survol)
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '4px',
    height: '100%',
    background: active ? 
      'linear-gradient(to bottom, #ad5389, #3c1053)' : 
      'transparent',
    left: 0,
    top: 0,
    opacity: active ? 1 : 0,
    transition: 'opacity 0.3s ease',
  },

  // Effet de bordure brillante pour l'état actif
  ...(active && {
    boxShadow: 'inset 0 0 10px rgba(173,83,137,0.1)',
    background: 'linear-gradient(120deg, rgba(173,83,137,0.1), rgba(60,16,83,0.1))',
    backdropFilter: 'blur(5px)',
  }),
}));

// Style amélioré pour le mini drawer (icônes seulement)
const MiniListItem = styled(ListItem)(({ active }) => ({
  margin: '8px auto',
  padding: '12px 0',
  width: '85%',
  position: 'relative',
  cursor: 'pointer',
  borderRadius: '12px',
  transition: 'all 0.3s ease',
  background: active ? 
    'linear-gradient(135deg, rgba(173, 83, 137, 0.15), rgba(60, 16, 83, 0.05))' : 
    'transparent',

  '&:hover': {
    background: 'rgba(173, 83, 137, 0.1)',
    transform: 'translateX(3px)',

    '& .MuiListItemIcon-root': {
      transform: 'scale(1.15)',
      color: '#ad5389',
      filter: 'drop-shadow(0 2px 4px rgba(173, 83, 137, 0.3))',
    },

    '&::after': {
      opacity: 1,
      transform: 'translateX(0)',
    }
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateX(-10px) translateY(-50%)',
    width: '4px',
    height: '20px',
    background: 'linear-gradient(to bottom, #ad5389, #3c1053)',
    borderRadius: '4px',
    opacity: 0,
    transition: 'all 0.3s ease',
  },

  '& .MuiListItemIcon-root': {
    color: active ? '#ad5389' : 'rgba(255,255,255,0.7)',
    transition: 'all 0.3s ease',
    margin: '0 auto',
    minWidth: 'unset',
  }
}));

// Style pour les dividers
const StyledDivider = styled(Divider)({
  margin: '16px 0',
  background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.1), transparent)',
  width: '80%',
  alignSelf: 'center',
});

// Style pour le titre de section
const SectionTitle = styled(Typography)({
  color: 'rgba(255,255,255,0.5)',
  fontSize: '0.75rem',
  letterSpacing: '1.5px',
  padding: '0 24px',
  marginTop: '24px',
  marginBottom: '8px',
  textTransform: 'uppercase',
  fontWeight: 500,
});

const defaultList = [
  {
    Icon: HomeOutlined,
    text: "Acceuil",
    route: '/'
  },
  {
    Icon: SportsEsportsOutlined,
    text: "Versus",
    route: '/versus'
  },
  {
    Icon: InsightsOutlined,
    text: "Ultra Lights",
    route: '/ultra'
  }
];

// Ajout d'un composant styled pour le contenu principal
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: '0 10px',
    paddingTop: '10px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `0`,
    }),
  }),
);

export default function DrawerNav({ children }) {
  const [open, setOpen] = useState(false);
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const { t } = useTranslation('SideBar');
  const { currentTheme, updateTheme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState('/');

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location]);

  const mySpaceList = [
    {
      Icon: Person4Outlined,
      text: "Mon espace",
      route: `/lighter-profile/${user?.preferred_username}`
    },
    {
      Icon: VideoLibraryOutlined,
      text: "Charger un highlight",
      route: '/upload'
    },
    {
      Icon: QuestionAnswerOutlined,
      text: "Messages",
      route: '/chats'
    },
    {
      Icon: GradeOutlined,
      text: "HIGHLIGHTS favoris",
      route: '/saved-high-lights'
    }
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigation = (route) => {
    if (route === '/') {
      updateTheme(DEFAULT_THEME.primary);
    }
    navigate(route);
    setOpen(false);
  };

  // Dans le rendu des items de la liste
  const renderListItem = (item, index, isActive, isOpen) => {
    const ItemComponent = isOpen ? FullWidthListItem : MiniListItem;
    
    return (
      <ItemComponent
        key={index}
        active={isActive}
        onClick={() => handleNavigation(item.route)}
      >
        <ListItemIcon>
          <item.Icon />
        </ListItemIcon>
        {isOpen && <ListItemText primary={item.text} />}
      </ItemComponent>
    );
  };

  return (
    <Box sx={{ 
      display: 'flex',
      minHeight: '100vh',
      width: '100%',
      overflow: 'hidden',
      position: 'relative',
      flexDirection: 'row',
      flex: 1,
    }}>
      <Drawer
        sx={{
          width: open ? DRAWER_WIDTH : MINI_DRAWER_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? DRAWER_WIDTH : MINI_DRAWER_WIDTH,
            boxSizing: 'border-box',
            background: `linear-gradient(135deg, 
              ${currentTheme.background}99 0%, 
              ${currentTheme.secondary}BB 100%
            )`,
            backdropFilter: 'blur(12px)',
            position: 'fixed',
            color: 'white',
            borderRight: '1px solid rgba(255, 255, 255, 0.08)',
            boxShadow: '5px 0 30px rgba(0,0,0,0.2)',
            borderRadius: '20px',
            margin: '0.6% 0 10px 0.5%',
            padding: open ? '0 10px' : '0 5px',
            height: 'calc(100vh - 20px)',
            transition: 'all 0.3s ease',
            // Suppression des scrollbars
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            msOverflowStyle: 'none',  // Pour IE et Edge
            scrollbarWidth: 'none',  // Pour Firefox
            overflow: 'hidden',
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'space-between',
          }
        }}
        variant="permanent"
        anchor="left"
        open={open}
      >
        <Box>
          <DrawerHeader>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -10,
                left: 0,
                width: '100%',
                height: '2px',
                background: 'linear-gradient(90deg, transparent, #FFD700, transparent)',
              }
            }}>
              <FlashOn 
                sx={{ 
                  color: '#FFD700',
                  fontSize: '2rem',
                  filter: 'drop-shadow(0 0 5px rgba(255, 215, 0, 0.5))',
                  animation: 'pulse 2s infinite',
                  '@keyframes pulse': {
                    '0%': { opacity: 0.6 },
                    '50%': { opacity: 1 },
                    '100%': { opacity: 0.6 }
                  }
                }} 
              />
              {open && (
                <>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      background: 'linear-gradient(45deg, #FFD700, #FFA500)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      fontWeight: 'bold',
                      letterSpacing: '1px',
                      textShadow: '0 2px 10px rgba(255,215,0,0.3)',
                    }}
                  >
                    HIGHLIGHTS
                  </Typography>
                  <IconButton 
                    onClick={handleDrawerClose}
                    sx={{ 
                      color: 'white',
                      '&:hover': {
                        transform: 'rotate(180deg)',
                        transition: 'transform 0.3s ease'
                      }
                    }}
                  >
                    <ChevronLeft />
                  </IconButton>
                </>
              )}
            </Box>
          </DrawerHeader>

          <List sx={{ 
            mt: 2,
            '& .MuiListItem-root': {
              py: 0.75,
            }
          }}>
            {defaultList.map((item, index) => 
              renderListItem(item, index, activeItem === item.route, open)
            )}
          </List>

          {isAuthenticated && (
            <>
              <Divider sx={{ bgcolor: 'rgba(255,255,255,0.1)', my: 1 }} />
              {open && (
                <Typography
                  variant="overline"
                  sx={{
                    px: 3,
                    py: 1,
                    color: 'rgba(255,255,255,0.7)',
                    letterSpacing: 1.2,
                  }}
                >
                  {t('my space').toUpperCase()}
                </Typography>
              )}
              <List>
                {mySpaceList.map((item, index) => 
                  renderListItem(item, index, activeItem === item.route, open)
                )}
              </List>
            </>
          )}
        </Box>

        {!open && (
          <IconButton
            onClick={handleDrawerOpen}
            sx={{
              margin: '20px auto',
              background: 'linear-gradient(45deg, #FFD700, #FFA500)',
              color: 'white',
              width: '40px',
              height: '40px',
              '&:hover': {
                background: 'linear-gradient(45deg, #FFA500, #FFD700)',
                transform: 'scale(1.1)',
                boxShadow: '0 0 15px rgba(255, 215, 0, 0.3)',
              },
              transition: 'all 0.3s ease',
            }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <DrawerStats isOpen={open} />
      </Drawer>

      <Main open={open}>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          height: 'auto',
          minHeight: '100vh',
        }}>
          {children}
        </Box>
      </Main>
    </Box>
  );
} 
import React, { useState } from 'react';
import { Box, Typography, IconButton, styled } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const GameStatsBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '550px',
  minHeight: '300px',
  background: 'rgba(255, 255, 255, 0.03)',
  borderRadius: '20px',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  padding: '34px 25px',
  marginLeft: '40px',
  boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  '@media (max-width: 768px)': {
    width: '90%',
    margin: '0 auto',
    padding: '25px 15px',
  },
}));

const GameTitle = styled(Typography)({
  color: 'white',
  fontSize: '1.3rem',
  fontWeight: 'bold',
  textAlign: 'left',
  padding: '1px 0',
  marginBottom: '5px',
  borderBottom: '2px solid rgba(255, 255, 255, 0.1)',
  paddingRight: '15px',
});

const TrophyGrid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'repeat(2, 90px)',
  columnGap: '15px',
  gap: '5px',
  rowGap: '30px',
  padding: '5px 0 15px 0',
  position: 'relative',
  transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out',
  marginBottom: '10px',
});

const TrophyItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
  padding: '8px',
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '12px',
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  height: '100%',
  maxHeight: '100px',
  '& > .MuiTypography-root': {
    '&:nth-of-type(1)': {
      fontSize: '1.5rem',
      marginBottom: '2px',
    },
    '&:nth-of-type(2)': {
      fontSize: '0.8rem',
      lineHeight: '1.2',
    },
    '&:nth-of-type(3)': {
      fontSize: '0.7rem',
      lineHeight: '1.1',
    }
  },
  '&:hover': {
    transform: 'translateY(-3px)',
    background: 'rgba(255, 255, 255, 0.08)',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
  },
});

const TrophyContainer = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
});

const CarouselButton = styled(IconButton)({
  position: 'absolute',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  padding: '8px',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 2,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  '&.left': {
    left: '5px',
  },
  '&.right': {
    right: '5px',
  },
});

export default function JeuxStat() {
  const [currentGameIndex, setCurrentGameIndex] = useState(0);
  const [isChanging, setIsChanging] = useState(false);

  const gameStats = [
    {
      game: 'Valorant',
      trophies: [
        { name: 'Diamond III', icon: '💎', description: 'Ranked' },
        { name: 'Ace', icon: '🎯', description: 'Achievement' },
        { name: 'Clutch Master', icon: '👑', description: 'Title' },
        { name: 'Top Fragger', icon: '🏆', description: 'Badge' },
        { name: 'Pro Player', icon: '⚔️', description: 'Status' },
        { name: 'Team MVP', icon: '🌟', description: 'Award' },
      ]
    },
    {
      game: 'League of Legends',
      trophies: [
        { name: 'Platinum II', icon: '🏅', description: 'Ranked' },
        { name: 'Pentakill', icon: '⚔️', description: 'Achievement' },
        // ... autres trophées
      ]
    },
    // ... autres jeux
  ];

  const handlePrevGame = () => {
    setIsChanging(true);
    setTimeout(() => {
      setCurrentGameIndex(prev => prev === 0 ? gameStats.length - 1 : prev - 1);
      setIsChanging(false);
    }, 200);
  };

  const handleNextGame = () => {
    setIsChanging(true);
    setTimeout(() => {
      setCurrentGameIndex(prev => prev === gameStats.length - 1 ? 0 : prev + 1);
      setIsChanging(false);
    }, 200);
  };

  return (
    <GameStatsBox>
      <GameTitle>
        {gameStats[currentGameIndex].game}
      </GameTitle>
      <TrophyContainer>
        <TrophyGrid
          sx={{
            opacity: isChanging ? 0 : 1,
            transform: isChanging ? 'translateX(20px)' : 'translateX(0)',
          }}
        >
          {gameStats[currentGameIndex].trophies.map((trophy, index) => (
            <TrophyItem 
              key={index}
              sx={{ 
                padding: '12px 8px',
              }}
            >
              <Typography sx={{ fontSize: '2rem' }}>
                {trophy.icon}
              </Typography>
              <Typography sx={{ 
                color: 'white', 
                fontSize: '0.9rem',
                fontWeight: 'bold' 
              }}>
                {trophy.name}
              </Typography>
              <Typography sx={{ 
                color: 'rgba(255,255,255,0.6)', 
                fontSize: '0.8rem' 
              }}>
                {trophy.description}
              </Typography>
            </TrophyItem>
          ))}
        </TrophyGrid>
      </TrophyContainer>
      <CarouselButton 
        className="left" 
        onClick={handlePrevGame}
      >
        <KeyboardArrowLeft />
      </CarouselButton>
      <CarouselButton 
        className="right" 
        onClick={handleNextGame}
      >
        <KeyboardArrowRight />
      </CarouselButton>
    </GameStatsBox>
  );
}
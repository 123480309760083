import { useState } from 'react';
import { 
  Box, 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Chip,
  LinearProgress,
  IconButton,
  styled 
} from '@mui/material';
import { 
  ExpandMore, 
  EmojiEvents,
  History,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close
} from '@mui/icons-material';
import { useTranslation } from "react-i18next";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(3px)',
  borderRadius: '12px !important',
  marginBottom: '10px',
  marginLeft: '4px',
  marginRight: '4px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root': {
    borderRadius: '12px',
    transition: 'all 0.3s ease',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
    }
  }
}));

const BattleProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  position: 'relative',
  overflow: 'hidden',

  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    transform: 'none !important',
    transformOrigin: 'left',
    transition: 'transform .4s ease',
    background: 'linear-gradient(90deg, #ffd700, #daa520)',
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    borderRadius: 5,
    background: 'linear-gradient(90deg, #8b0000, #dc143c)',
    transition: 'width .4s ease',
  }
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  '&.Mui-disabled': {
    color: 'rgba(255, 255, 255, 0.3)',
  }
}));

const BattlesContainer = styled(Box)({
  maxHeight: '500px',
  overflowY: 'auto',
  position: 'relative',
  padding: '4px',
  borderRadius: '16px',
  background: 'rgba(255, 255, 255, 0.02)',
  '&::-webkit-scrollbar': {
    width: '8px',
    background: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '4px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.3)',
    }
  },
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.05)',
  '&::before': {
    content: '""',
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    height: '20px',
    background: 'linear-gradient(to bottom, rgba(17, 17, 17, 0.9) 0%, rgba(17, 17, 17, 0) 100%)',
    backdropFilter: 'blur(3px)',
    zIndex: 1,
    borderRadius: '16px 16px 0 0',
  },
  '&::after': {
    content: '""',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    height: '20px',
    background: 'linear-gradient(to top, rgba(17, 17, 17, 0.9) 0%, rgba(17, 17, 17, 0) 100%)',
    backdropFilter: 'blur(3px)',
    zIndex: 1,
    borderRadius: '0 0 16px 16px',
  }
});

const BattleCount = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '12px',
  padding: '4px 12px',
  marginLeft: '12px',
  backdropFilter: 'blur(5px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
});

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffTime = Math.abs(now - date);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) {
    return "Aujourd'hui";
  } else if (diffDays === 1) {
    return "Hier";
  } else if (diffDays < 7) {
    return `Il y a ${diffDays} jours`;
  } else {
    return date.toLocaleDateString('fr-FR', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  }
};

export default function BattlesList({ battles = [], username }) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (!battles || battles.length === 0) {
    return (
      <Box sx={{ mt: 4, mx: 4 }}>
        <Typography 
          variant="h5" 
          sx={{ 
            mb: 3, 
            color: 'rgba(255, 255, 255, 0.95)',
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
            fontFamily: '"Rajdhani", sans-serif',
            fontWeight: 600,
            letterSpacing: '0.5px',
            fontSize: '1.5rem',
          }}
        >
          <History sx={{ 
            fontSize: '1.8rem',
            opacity: 0.9,
            color: 'rgba(255, 255, 255, 0.8)'
          }} />
          Historique des Battles
        </Typography>
        <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
          Aucune battle trouvée
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4, mx: 4 }}>
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        mb: 3,
      }}>
        <Typography 
          variant="h5" 
          sx={{ 
            color: 'rgba(255, 255, 255, 0.95)',
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
            fontFamily: '"Rajdhani", sans-serif',
            fontWeight: 600,
            letterSpacing: '0.5px',
            fontSize: '1.5rem',
          }}
        >
          <History sx={{ 
            fontSize: '1.8rem',
            opacity: 0.9,
            color: 'rgba(255, 255, 255, 0.8)'
          }} />
          Historique des Battles
        </Typography>
        <BattleCount>
          <Typography 
            sx={{ 
              color: 'rgba(255, 255, 255, 0.9)',
              fontSize: '0.9rem',
              fontWeight: 500,
              fontFamily: '"Rajdhani", sans-serif'
            }}
          >
            {battles.length} {battles.length > 1 ? 'battles' : 'battle'}
          </Typography>
        </BattleCount>
      </Box>

      <BattlesContainer>
        {battles.map((battle) => {
          const isVideo1 = battle.video1.username === username;
          const userVideo = isVideo1 ? battle.video1 : battle.video2;
          const opponentVideo = isVideo1 ? battle.video2 : battle.video1;
          
          const hasWon = battle.winnerId === userVideo.id;

          return (
            <StyledAccordion 
              key={battle.id}
              expanded={expanded === battle.id}
              onChange={handleChange(battle.id)}
            >
              <AccordionSummary expandIcon={<ExpandMore sx={{ color: 'white' }} />}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'space-between',
                  width: '100%',
                  pr: 2 
                }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography sx={{ color: 'white', fontWeight: 'bold' }}>
                        {battle.video1.title} VS {battle.video2.title}
                      </Typography>
                      {!battle.isActive && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {hasWon ? (
                            <EmojiEvents sx={{ color: 'gold' }} />
                          ) : (
                            <Close sx={{ color: 'red' }} />
                          )}
                        </Box>
                      )}
                    </Box>
                    <Typography 
                      sx={{ 
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontSize: '0.85rem',
                        fontStyle: 'italic'
                      }}
                    >
                      {formatDate(battle.date)}
                    </Typography>
                  </Box>
                  <Chip 
                    label={battle.isActive ? 'En cours' : 'Terminé'}
                    sx={{
                      backgroundColor: battle.isActive ? 'rgba(46, 213, 115, 0.2)' : 'rgba(255, 255, 255, 0.1)',
                      color: battle.isActive ? '#2ed573' : 'white',
                    }}
                  />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ mt: 2 }}>
                  <Box sx={{ mb: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography sx={{ color: 'white' }}>
                        {battle.video1.username}
                      </Typography>
                      <Typography sx={{ color: 'white' }}>
                        {battle.video2.username}
                      </Typography>
                    </Box>
                    <BattleProgress 
                      variant="determinate"
                      value={battle.video1.votes / battle.totalVotes * 100 || 0}
                      sx={{
                        '& .MuiLinearProgress-bar': {
                          background: battle.video1.votes > battle.video2.votes 
                            ? 'linear-gradient(90deg, #ffd700, #daa520)' 
                            : 'linear-gradient(90deg, #8b0000, #dc143c)',
                          width: `${battle.video1.votes / battle.totalVotes * 100}%`,
                        },
                        '&::after': {
                          background: battle.video1.votes > battle.video2.votes 
                            ? 'linear-gradient(90deg, #8b0000, #dc143c)' 
                            : 'linear-gradient(90deg, #ffd700, #daa520)',
                          width: `${battle.video2.votes / battle.totalVotes * 100}%`,
                        }
                      }}
                    />
                  </Box>
                  
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    color: 'rgba(255, 255, 255, 0.7)'
                  }}>
                    <Typography>
                      {battle.video1.votes} votes
                    </Typography>
                    <Typography>
                      {battle.video2.votes} votes
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </StyledAccordion>
          );
        })}
      </BattlesContainer>
    </Box>
  );
} 
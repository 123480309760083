import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { SportsSoccer, EmojiEvents, Psychology, Whatshot } from '@mui/icons-material';
import sportHeader from '../../assets/headerCategoriesPhotos/sport.jpg';

export function SportsPage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const sportsInfoCards = [
    {
      icon: <SportsSoccer />,
      title: 'Actions Décisives',
      desc: 'Les moments qui ont changé le cours du match'
    },
    {
      icon: <Psychology />,
      title: 'Tactiques Gagnantes',
      desc: 'Les stratégies qui ont mené à la victoire'
    },
    {
      icon: <Whatshot />,
      title: 'Gestes Techniques',
      desc: 'Les plus beaux mouvements et skills'
    },
    {
      icon: <EmojiEvents />,
      title: 'Moments de Gloire',
      desc: 'Les célébrations et victoires mémorables'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="SPORTS ARENA"
        description="Vivez l'intensité du sport virtuel à son apogée. Des actions spectaculaires aux moments décisifs, votez pour les performances qui méritent leur place dans l'histoire."
        color="#5B8A72"
        backgroundImage={sportHeader}
        infoCards={sportsInfoCards}
      />

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#5B8A72"
              buttonGradient={{
                from: '#5B8A72',
                to: '#3D5B4C'
              }}
              hoverShadowColor="rgba(91, 138, 114, 0.5)"
              vsColor="#5B8A72"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
} 
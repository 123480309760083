import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '../../context/ThemeContext';

export default function GlassBox({ children }) {
  const { currentTheme } = useTheme();

  return (
    <Box sx={{ 
      flexGrow: 1, 
      borderRadius: '19px',
      minHeight: '102vh',
      padding: 3,
      position: 'relative',
      background: `linear-gradient(135deg, ${currentTheme.primary}33 0%, ${currentTheme.secondary}33 100%)`,
      backdropFilter: 'blur(20px)',
      boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
      border: '1px solid rgba(255, 255, 255, 0.08)',
      overflowX: 'hidden',
      overflowY: 'visible',

      // Effet de brillance animé
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: -100,
        width: '50%',
        height: '100%',
        background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.12), transparent)',
        transform: 'skewX(-15deg)',
        animation: 'shine 4s infinite',
        pointerEvents: 'none',
      },

      // Animation de brillance
      '@keyframes shine': {
        '0%': {
          left: '-100%',
          opacity: 0,
        },
        '20%': {
          opacity: 0.6,
        },
        '100%': {
          left: '200%',
          opacity: 0,
        },
      },

      // Overlay subtil
      '&::after': {
        content: '""',
        position: 'absolute',
        inset: 0,
        background: 'radial-gradient(circle at 50% 0%, rgba(255, 255, 255, 0.1), transparent 50%)',
        pointerEvents: 'none',
        borderRadius: 'inherit',
      },

      // S'assurer que le contenu reste au-dessus des effets
      '& > *': {
        position: 'relative',
      },
    }}>
      {children}
    </Box>
  );
}

GlassBox.propTypes = {
  children: PropTypes.node.isRequired,
}; 
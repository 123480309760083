import React from 'react';
import { Box, Chip } from '@mui/material';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PropTypes from 'prop-types';

export default function CategoryChips({ onCategoryClick }) {
  // Style commun pour les Chips
  const chipStyle = {
    border: '1px solid',
    borderColor: 'rgba(255, 255, 255, 0.3)',
    background: 'rgba(255, 255, 255, 0.05)',
    backdropFilter: 'blur(8px)',
    color: 'white',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 0 15px rgba(255, 255, 255, 0.3)',
      background: 'rgba(255, 255, 255, 0.1)',
      borderColor: 'rgba(255, 255, 255, 0.8)',
    },
    '&.active': {
      borderColor: '#ad5389',
      background: 'linear-gradient(45deg, rgba(173, 83, 137, 0.1), rgba(60, 16, 83, 0.1))',
      boxShadow: '0 0 15px rgba(173, 83, 137, 0.3)',
    }
  };

  // Styles spécifiques pour chaque catégorie
  const categoryStyles = {
    trending: {
      ...chipStyle,
      borderColor: '#ff4d4d',
      '&:hover': {
        ...chipStyle['&:hover'],
        borderColor: '#ff4d4d',
        boxShadow: '0 0 15px rgba(255, 77, 77, 0.3)',
      }
    },
    action: {
      ...chipStyle,
      borderColor: '#4d79ff',
      '&:hover': {
        ...chipStyle['&:hover'],
        borderColor: '#4d79ff',
        boxShadow: '0 0 15px rgba(77, 121, 255, 0.3)',
      }
    },
    fps: {
      ...chipStyle,
      borderColor: '#4dff4d',
      '&:hover': {
        ...chipStyle['&:hover'],
        borderColor: '#4dff4d',
        boxShadow: '0 0 15px rgba(77, 255, 77, 0.3)',
      }
    },
    moba: {
      ...chipStyle,
      borderColor: '#ffff4d',
      '&:hover': {
        ...chipStyle['&:hover'],
        borderColor: '#ffff4d',
        boxShadow: '0 0 15px rgba(255, 255, 77, 0.3)',
      }
    },
    battleRoyale: {
      ...chipStyle,
      borderColor: '#ff4dff',
      '&:hover': {
        ...chipStyle['&:hover'],
        borderColor: '#ff4dff',
        boxShadow: '0 0 15px rgba(255, 77, 255, 0.3)',
      }
    },
    popular: {
      ...chipStyle,
      borderColor: '#4dfff4',
      '&:hover': {
        ...chipStyle['&:hover'],
        borderColor: '#4dfff4',
        boxShadow: '0 0 15px rgba(77, 255, 244, 0.3)',
      }
    }
  };

  return (
    <Box sx={{ 
      mb: 4, 
      mt: 3,
      display: 'flex', 
      gap: 2, 
      overflowX: 'auto', 
      pb: 1,
      pt: 2,
      '&::-webkit-scrollbar': {
        height: '4px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '2px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(255, 255, 255, 0.3)',
        borderRadius: '2px',
        '&:hover': {
          background: 'rgba(255, 255, 255, 0.5)',
        },
      },
    }}>
      <Chip 
        icon={<WhatshotIcon />} 
        label="Trending" 
        clickable 
        onClick={() => onCategoryClick('trending')}
        sx={categoryStyles.trending}
      />
      <Chip 
        icon={<SportsEsportsIcon />} 
        label="Action" 
        clickable 
        onClick={() => onCategoryClick('action')}
        sx={categoryStyles.action}
      />
      <Chip 
        label="FPS" 
        clickable 
        onClick={() => onCategoryClick('fps')}
        sx={categoryStyles.fps}
      />
      <Chip 
        label="MOBA" 
        clickable 
        onClick={() => onCategoryClick('moba')}
        sx={categoryStyles.moba}
      />
      <Chip 
        label="Battle Royale" 
        clickable 
        onClick={() => onCategoryClick('battleRoyale')}
        sx={categoryStyles.battleRoyale}
      />
      <Chip 
        icon={<TrendingUpIcon />} 
        label="Populaire" 
        clickable 
        onClick={() => onCategoryClick('popular')}
        sx={categoryStyles.popular}
      />
    </Box>
  );
}

CategoryChips.propTypes = {
  onCategoryClick: PropTypes.func
}; 
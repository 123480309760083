import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { GpsFixed, Speed, EmojiEvents, Whatshot } from '@mui/icons-material';
import fpsHeader from '../../assets/headerCategoriesPhotos/fpsHeader.jpg';

export function FpsPage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const fpsInfoCards = [
    {
      icon: <GpsFixed />,
      title: 'Précision Ultime',
      desc: 'Les meilleurs headshots et flicks de la communauté'
    },
    {
      icon: <Speed />,
      title: 'Réflexes Surhumains',
      desc: `Des réactions à la vitesse de l'éclair`
    },
    {
      icon: <EmojiEvents />,
      title: 'Compétition',
      desc: 'Votez pour les meilleures actions'
    },
    {
      icon: <Whatshot />,
      title: 'Moments Épiques',
      desc: 'Des clutchs et des aces légendaires'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="FPS ARENA"
        description="Découvrez et votez pour les moments les plus intenses des jeux de tir. Chaque highlight est une démonstration de skill, de précision et de réflexes surhumains."
        color="#ff4757"
        backgroundImage={fpsHeader}
        infoCards={fpsInfoCards}
      />

      <Typography 
        variant="h2" 
        color="white" 
        sx={{ 
          mb: 4,
          fontFamily: '"Orbitron", sans-serif',
          fontSize: '3.5rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          letterSpacing: '8px',
          position: 'relative',
          display: 'inline-block',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: -40,
            width: 30,
            height: 2,
            background: '#ff4757',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            right: -40,
            width: 30,
            height: 2,
            background: '#ff4757',
          }
        }}
      >
        FPS
      </Typography>

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#ff4757"
              buttonGradient={{
                from: '#ff4757',
                to: '#802436'
              }}
              hoverShadowColor="rgba(255, 71, 87, 0.5)"
              vsColor="#ff4757"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
} 
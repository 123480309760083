import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../context/keycloakContext";
import LargeHighlightBattle from '../../components/Feed/LargeHighlightBattle';
import { Api } from '../../services/Api';
import { Typography } from '@mui/material';
import HeaderCategorie from '../../components/CategoryChips/HeaderCategorie';
import { PhoneAndroid, Psychology, EmojiEvents, Whatshot } from '@mui/icons-material';
import mobileHeader from '../../assets/headerCategoriesPhotos/mobile.jpg';

export function MobilePage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const [videoPairs, setVideoPairs] = useState([]);

  const mobileInfoCards = [
    {
      icon: <PhoneAndroid />,
      title: 'Skills Mobiles',
      desc: 'La maîtrise tactile à son apogée'
    },
    {
      icon: <Psychology />,
      title: 'Tactiques Pro',
      desc: 'Les stratégies adaptées au jeu mobile'
    },
    {
      icon: <Whatshot />,
      title: 'Plays Incroyables',
      desc: 'Les actions qui semblent impossibles sur mobile'
    },
    {
      icon: <EmojiEvents />,
      title: 'Moments Clutch',
      desc: 'Les retournements de situation épiques'
    }
  ];

  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); 
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <HeaderCategorie 
        title="MOBILE ARENA"
        description="Découvrez l'excellence du gaming mobile. Des contrôles parfaits aux stratégies uniques, votez pour les performances qui prouvent que le mobile gaming est un art à part entière."
        color="#6c5ce7"
        backgroundImage={mobileHeader}
        infoCards={mobileInfoCards}
      />

      <div style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <LargeHighlightBattle 
              key={`battle_${index}`} 
              videoPair={pair.videos}
              matchId={pair.matchId}
              borderColor="#6c5ce7"
              buttonGradient={{
                from: '#6c5ce7',
                to: '#1e3d59'
              }}
              hoverShadowColor="rgba(108, 92, 231, 0.5)"
              vsColor="#6c5ce7"
            />
          ))
        ) : (
          <Typography color="gray">{tCommon('Chargement des battles...')}</Typography>
        )}
      </div>
    </div>
  );
} 
import '@vidstack/react/player/styles/default/theme.css';

import styles from './player.module.css';

import {
  MediaPlayer,
  MediaProvider,
} from '@vidstack/react';
import VideoLayout from './layouts/video-layout';
import PropTypes from 'prop-types';
import { useRef } from 'react';

CustomPlayer.propTypes = {
  layoutType: PropTypes.string,
  src: PropTypes.string.isRequired,
};

export default function CustomPlayer({ layoutType, src }) {
  const videoRef = useRef(null);

  if (layoutType === 'ultraMinimal') {
    return (
      <video
        ref={videoRef}
        src={src}
        autoPlay={false}
        loop
        muted
        playsInline
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: 'inherit'
        }}
        onMouseEnter={(e) => e.target.play()}
        onMouseLeave={(e) => {
          e.target.pause();
          e.target.currentTime = 0;
        }}
      />
    );
  }

  return (
    <MediaPlayer
      className={`${styles.player} media-player`}
      title={"Video Player"}
      playsInline
      src={{
        src: `${src}`,
        type: "video/mp4"
      }}
    >
      <MediaProvider />
      <VideoLayout
        layoutType={layoutType}
      />
    </MediaPlayer>
  );
}
